import {GetProfileDetailsResponseDto} from '../../../messaging-generated-code'
import {GetProfileDetailsResponse} from '../../userProfileService'

export const getProfileDetailsResponseMapper = (dto: GetProfileDetailsResponseDto): GetProfileDetailsResponse => {
   return {
      accountName: dto.accountName,
      contactEmail: dto.externalContactEmail,
      contactName: dto.externalContactName,
   }
}
