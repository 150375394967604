import {
   Button,
   Col,
   Container,
   Form,
   FormFeedback,
   FormGroup,
   Input,
   InputGroup,
   InputGroupAddon,
   Label,
   LoadingIcon,
   LoadingOverlay,
   Row,
} from '@gs-ux-uitoolkit-react/core'
import { Key, useFormState } from '@uieng/common'
import React, { useCallback, useState } from 'react'
import { ResponseDefinition, ResponseState } from '@uieng/messaging-api'

import { PasswordChangeResult } from '../../reset-password-api'
import cn from 'classnames'
import styles from './styles.module.scss'

interface Props {
   changePasswordResponse: ResponseDefinition<PasswordChangeResult>
   isPasswordChangeRequired: boolean

   onCancel: () => void
   onSubmit: (username: string, currentPassword: string, newPassword: string) => void
}

type ValidationState = 'success' | 'danger'

const NEW_PASSWORD_MIN_LENGTH = 16
const NEW_PASSWORD_MAX_LENGTH = 25

const RenderResetPasswordView: React.FC<Props> = (props) => {
   const { onSubmit, isPasswordChangeRequired } = props
   const [username, setUsername] = useFormState<string>('')
   const [currentPassword, setCurrentPassword] = useFormState<string>('')
   const [newPassword, setNewPassword] = useFormState<string>('')
   const [newPasswordRepeat, setNewPasswordRepeat] = useFormState<string>('')

   const [usernameState, setUsernameState] = useState<ValidationState>()
   const [currentPasswordState, setCurrentPasswordState] = useState<ValidationState>()
   const [newPasswordState, setNewPasswordState] = useState<ValidationState>()
   const [newPasswordRepeatState, setNewPasswordRepeatState] = useState<ValidationState>()

   const [usernameFeedback, setUsernameFeedback] = useState<string>()
   const [currentPasswordFeedback, setCurrentPasswordFeedback] = useState<string>()
   const [newPasswordFeedback, setNewPasswordFeedback] = useState<string>()
   const [newPasswordRepeatFeedback, setNewPasswordRepeatFeedback] = useState<string>()

   const handleSubmitClick = useCallback(() => {
      const isUsernameValid = username != null && username.length >= 3
      const isCurrentPasswordValid = currentPassword != null
      const isNewPasswordTooShort = newPassword != null && newPassword.length < NEW_PASSWORD_MIN_LENGTH
      const isNewPasswordTooLong = newPassword != null && newPassword.length > NEW_PASSWORD_MAX_LENGTH

      const isNewPasswordValid = !isNewPasswordTooShort && !isNewPasswordTooLong
      const isNewPasswordRepeatValid = newPasswordRepeat != null && newPasswordRepeat === newPassword

      setUsernameState(isUsernameValid ? 'success' : 'danger')
      setCurrentPasswordState(isCurrentPasswordValid ? 'success' : 'danger')
      setNewPasswordState(isNewPasswordValid ? 'success' : 'danger')
      setNewPasswordRepeatState(isNewPasswordRepeatValid ? 'success' : 'danger')

      setUsernameFeedback(isUsernameValid ? undefined : 'The username is too short')
      setCurrentPasswordFeedback(isCurrentPasswordValid ? undefined : 'The password is too short')
      setNewPasswordFeedback(
         isNewPasswordValid ? undefined : `The password is too ${isNewPasswordTooLong ? 'long' : 'short'}`,
      )
      setNewPasswordRepeatFeedback(isNewPasswordRepeatValid ? undefined : 'The password is too short')

      if (isNewPasswordValid && isNewPasswordRepeatValid) {
         const arePasswordsSameAsOld = currentPassword === newPassword
         const arePasswordsNotEqual = newPassword !== newPasswordRepeat

         setNewPasswordState(arePasswordsSameAsOld || arePasswordsNotEqual ? 'danger' : 'success')
         setNewPasswordRepeatState(arePasswordsSameAsOld || arePasswordsNotEqual ? 'danger' : 'success')

         if (arePasswordsSameAsOld) {
            setNewPasswordFeedback(
               arePasswordsSameAsOld ? 'New password cannot be the same as your old password' : undefined,
            )
            setNewPasswordRepeatFeedback(
               arePasswordsSameAsOld ? 'New password cannot be the same as your old password' : undefined,
            )
         } else if (arePasswordsNotEqual) {
            setNewPasswordFeedback(arePasswordsNotEqual ? 'New passwords do not match' : undefined)
            setNewPasswordRepeatFeedback(arePasswordsNotEqual ? 'New passwords do not match' : undefined)
         } else {
            onSubmit(username, currentPassword, newPassword)
         }
      }
   }, [
      username,
      currentPassword,
      newPassword,
      newPasswordRepeat,
      setUsernameState,
      setCurrentPasswordState,
      setNewPasswordState,
      setNewPasswordRepeatState,
      onSubmit,
   ])

   const handleInputEnter = useCallback(
      (event: React.KeyboardEvent) => {
         if (event.charCode === Key.Enter) {
            handleSubmitClick()
         }
      },
      [handleSubmitClick],
   )

   let feedbackMessage

   if (props.changePasswordResponse.state === ResponseState.Error) {
      const message =
         props.changePasswordResponse.data === PasswordChangeResult.ServerError
            ? 'Password change failed due to server error. Please try again.'
            : "Password changed failed. Invalid password or doesn't match complexity."

      feedbackMessage = <p className={cn('gs-uitk-text-body-03', styles.errorFeedbackLabel)}>{message}</p>
   }

   return (
      <Container className={styles.rootContainer}>
         <Row className="justify-content-center">
            <Col sm="6" className={cn('gs-uitk-shadow-100', styles.contentPanel)}>
               <h4> Password Reset Form </h4>
               <div className={styles.passwordRules}>
                  <p style={{ color: 'red' }}>
                     {' '}
                     {isPasswordChangeRequired
                        ? '(Login with temporary detected. Must reset password to continue)'
                        : ''}{' '}
                  </p>
                  <span>New password must meet the complexity requirements below:</span>
                  <ul>
                     <li>is not the Account Name itself (or it's reverse)</li>
                     <li>should not contain fragments (3 characters or more) of the username</li>
                     <li>
                        is {NEW_PASSWORD_MIN_LENGTH}-{NEW_PASSWORD_MAX_LENGTH} characters long
                     </li>
                  </ul>
                  And also have the following requirements:
                  <ul>
                     <li>has an upper case letter</li>
                     <li>has a lower case letter</li>
                     <li>has a numeric</li>
                     <li>has a special character</li>
                  </ul>
               </div>

               <Form>
                  <FormGroup color={usernameState}>
                     <Label for="username">Username</Label>

                     <InputGroup>
                        <InputGroupAddon addonType="prepend">
                           <div className="input-group-text">
                              <i className="gsi gsi-account" />
                           </div>
                        </InputGroupAddon>

                        <Input
                           placeholder="Enter your username"
                           type="text"
                           name="username"
                           id="username"
                           state={usernameState}
                           valid={usernameState === 'success'}
                           invalid={usernameState === 'danger'}
                           value={username}
                           onKeyPress={handleInputEnter}
                           onChange={setUsername}
                        />

                        <FormFeedback valid={usernameState === 'success'}>{usernameFeedback}</FormFeedback>
                     </InputGroup>
                  </FormGroup>

                  <FormGroup color={currentPasswordState}>
                     <Label for="currentPassword">Current Password</Label>

                     <InputGroup>
                        <InputGroupAddon addonType="prepend">
                           <div className="input-group-text">
                              <i className="gsi gsi-lock" />
                           </div>
                        </InputGroupAddon>

                        <Input
                           placeholder="Enter your current password"
                           type="password"
                           name="currentPassword"
                           id="currentPassword"
                           state={currentPasswordState}
                           valid={currentPasswordState === 'success'}
                           invalid={currentPasswordState === 'danger'}
                           value={currentPassword}
                           onKeyPress={handleInputEnter}
                           onChange={setCurrentPassword}
                        />

                        <FormFeedback valid={currentPasswordState === 'success'}>
                           {currentPasswordFeedback}
                        </FormFeedback>
                     </InputGroup>
                  </FormGroup>

                  <FormGroup color={newPasswordState}>
                     <Label for="newPassword">New Password</Label>

                     <InputGroup>
                        <InputGroupAddon addonType="prepend">
                           <div className="input-group-text">
                              <i className="gsi gsi-lock" />
                           </div>
                        </InputGroupAddon>

                        <Input
                           placeholder="Enter your new password"
                           type="password"
                           name="newPassword"
                           id="newPassword"
                           state={newPasswordState}
                           valid={newPasswordState === 'success'}
                           invalid={newPasswordState === 'danger'}
                           value={newPassword}
                           onKeyPress={handleInputEnter}
                           onChange={setNewPassword}
                        />

                        <FormFeedback valid={newPasswordState === 'success'}>{newPasswordFeedback}</FormFeedback>
                     </InputGroup>
                  </FormGroup>

                  <FormGroup color={newPasswordRepeatState}>
                     <Label for="newPasswordRepeat">New Password (repeat)</Label>

                     <InputGroup>
                        <InputGroupAddon addonType="prepend">
                           <div className="input-group-text">
                              <i className="gsi gsi-lock" />
                           </div>
                        </InputGroupAddon>

                        <Input
                           placeholder="Enter your new password again"
                           type="password"
                           name="newPasswordRepeat"
                           id="newPasswordRepeat"
                           state={newPasswordRepeatState}
                           valid={newPasswordRepeatState === 'success'}
                           invalid={newPasswordRepeatState === 'danger'}
                           value={newPasswordRepeat}
                           onKeyPress={handleInputEnter}
                           onChange={setNewPasswordRepeat}
                        />

                        <FormFeedback valid={newPasswordRepeatState === 'success'}>
                           {newPasswordRepeatFeedback}
                        </FormFeedback>
                     </InputGroup>
                  </FormGroup>

                  {feedbackMessage}

                  <div className={styles.actionsContainer}>
                     <Button onClick={props.onCancel}>Cancel</Button>

                     <Button color="primary" onClick={handleSubmitClick}>
                        Submit
                     </Button>
                  </div>

                  <LoadingOverlay show={props.changePasswordResponse.state === ResponseState.Loading}>
                     <LoadingIcon />
                  </LoadingOverlay>
               </Form>
            </Col>
         </Row>
      </Container>
   )
}

export const ResetPasswordView = React.memo(RenderResetPasswordView)
