import {createMutableReducer} from '@uieng/common'
import {ResponseState} from '@uieng/messaging-api'
import {AuthActionTypes} from '../../authentication-api'
import {ShellActionTypes, ShellState} from '../../shell-api'
import {AllActions, AllActionTypes} from '../../system-api'

const initialData: ShellState = {
   userIP: '',
   bootstrapState: ResponseState.Loading,
}

export const shellReducer = createMutableReducer<AllActionTypes, AllActions, ShellState>(
   {
      [ShellActionTypes.BOOTSTRAP_STARTED]: (draft, action) => {
         draft.config = action.payload.config
      },
      [AuthActionTypes.BOOTSTRAP_FINISHED]: (draft) => {
         draft.bootstrapState = ResponseState.Success
      },
      [ShellActionTypes.GET_USER_IP_ADDRESS_RESULT]: (draft, action) => {
         const result = action.payload

         draft.userIP = result.response ?? 'Unknown'
      },
   },
   initialData,
)
