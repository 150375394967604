import {ColumnApi, GridApi, GridOptions, GridReadyEvent} from '@ag-grid-community/core'
import {DataGrid as Grid, RowDataUpdatedEvent} from '@gs-ux-uitoolkit-react/datagrid'

import {FileEntry} from '../../../directory-list-api'
import React from 'react'
import {createGridConfig} from './configuration'
import {getLogger} from '@uieng/logger'

interface Props {
   className?: string
   data: FileEntry[]
   loading: boolean

   onFileNameClick: (entry: FileEntry) => void
   onDeleteClick: (entry: FileEntry) => void
}

interface State {
   gridConfig: GridOptions
}

const logger = getLogger('DataGrid')

export class DataGrid extends React.Component<Props, State> {
   private _gridApi: GridApi | null = null
   private _gridColumnApi: ColumnApi | null = null

   constructor(props: Props) {
      super(props)

      this.state = {
         gridConfig: createGridConfig({
            onFileNameClick: props.onFileNameClick,
            onDeleteClick: props.onDeleteClick,
         }),
      }
   }

   componentDidUpdate(previousProps: Props) {
      if (this._gridApi == null || this._gridColumnApi == null) {
         return
      }

      if (previousProps.loading !== this.props.loading) {
         this._toggleGridOverlay(this.props.loading)
      }
   }

   private _toggleGridOverlay(loading: boolean) {
      if (this._gridApi == null) {
         return
      }

      logger.debug(`Setting loading overlay to [${loading}].`)

      if (loading) {
         this._gridApi.showLoadingOverlay()
      } else {
         this._gridApi.hideOverlay()
      }
   }

   private _onRowDataUpdated(event: RowDataUpdatedEvent) {
      event.api.setFilterModel(null)
   }

   render() {
      return (
         <div className={this.props.className}>
            <Grid
               id={`YYY`}
               rowData={this.props.data}
               gridOptions={this.state.gridConfig}
               onGridReady={this._onGridReady}
               onRowDataUpdated={this._onRowDataUpdated}
            />
         </div>
      )
   }

   private _onGridReady = (event: GridReadyEvent) => {
      this._gridApi = event.api
      this._gridColumnApi = event.columnApi

      this._toggleGridOverlay(this.props.loading)
   }
}
