import {ofType} from '@martin_hotell/rex-tils'
import {map} from 'rxjs/operators'
import {ShellEpic} from '..'
import {ShellActions, ShellActionTypes} from '../../shell-api'

export const disposeShellModule: ShellEpic = (action$) =>
   action$.pipe(
      ofType(ShellActionTypes.DISPOSE_STARTED),
      map(() => ShellActions.disposeFinished()),
   )
