import {MessagingClient, OperationDefinition} from '@uieng/messaging-api'
import {Observable} from 'rxjs'

export interface ListFilesRequestDto {
   filelocation: string
}

export interface ListFilesResponseDto {
   absolutePath: string
   fileName: string
   hidden: boolean
   directory: boolean
   file: boolean
   link: boolean
   doesExist: boolean
   readable: boolean
   writeable: boolean
   removable: boolean
   ownerName: string
   groupName: string
   linkCount: number
   lastModified: number
   size: number
   permissions: string
}

export interface DeleteFilesRequestDto {
   filelocation: string
}

export interface DeleteFilesResponseDto {}

export const listFilesDefinition: OperationDefinition<RequestInit> = {
   requestName: 'files/listing',
   serviceName: '',
   requestOptions: {
      method: 'GET',
      headers: {
         'Content-Type': 'application/json',
      },
   },
}

export const deleteFilesDefinition: OperationDefinition<RequestInit> = {
   requestName: 'file',
   serviceName: '',
   requestOptions: {
      method: 'DELETE',
      headers: {
         'Content-Type': 'application/json',
      },
   },
}

export const downloadFilesDefinition: OperationDefinition<RequestInit> = {
   requestName: 'file',
   serviceName: '',
   requestOptions: {
      method: 'GET',
      headers: {
         'Content-Type': 'application/json',
      },
   },
}

export const uploadFileDefinition: OperationDefinition<RequestInit> = {
   requestName: 'file',
   serviceName: '',
   requestOptions: {
      method: 'PUT',
   },
}

export interface FilesManagementReactiveClient {
   listFiles(request: ListFilesRequestDto, options?: RequestInit): Observable<ListFilesResponseDto[]>

   deleteFiles(request: DeleteFilesRequestDto, options?: RequestInit): Observable<DeleteFilesResponseDto>
}

export class DefaultFilesManagementReactiveClient implements FilesManagementReactiveClient {
   constructor(private readonly _messagingClient: MessagingClient<RequestInit>) {}

   listFiles(request: ListFilesRequestDto, options?: RequestInit): Observable<ListFilesResponseDto[]> {
      return this._messagingClient.requestResponse(listFilesDefinition, request, options)
   }

   deleteFiles(request: DeleteFilesRequestDto, options?: RequestInit): Observable<DeleteFilesResponseDto> {
      return this._messagingClient.requestResponse(deleteFilesDefinition, request, options)
   }
}
