import {ResponseState} from '@uieng/messaging-api'
import {ModuleApi} from '@uieng/polimer-js'
import React from 'react'
import {useSelector} from 'react-redux'
import {ApplicationGlobalState} from '../../../system-api'
import {getActiveModuleSelector} from '../../selectors'
import {WorkspaceView} from './workspaceView'

interface Props {
   className?: string
}

interface StoreProps {
   activeModules: ModuleApi[]
   bootstrapState: ResponseState
}

const RenderWorkspaceContainer: React.FC<Props> = (props) => {
   const storeProps = useSelector<ApplicationGlobalState, StoreProps>((store) => ({
      activeModules: getActiveModuleSelector(store.polimer, store.routing),
      bootstrapState: store.shell.bootstrapState,
   }))

   return (
      <WorkspaceView
         activeModules={storeProps.activeModules}
         className={props.className}
         bootstrapState={storeProps.bootstrapState}
      />
   )
}

export const WorkspaceContainer = React.memo(RenderWorkspaceContainer)
