import {Features, ShellActionTypes} from '../../shell-api'
import {flatMap, ignoreElements, tap} from 'rxjs/operators'

import {AnalyticsService} from '../services/analyticsService'
import {ShellEpic} from '..'
import {getLogger} from '@uieng/logger'
import {ofType} from '@martin_hotell/rex-tils'

const logger = getLogger('AnalyticsLogger')

export const analyticsEpic: ShellEpic = (action$, state$, deps) =>
   action$.pipe(
      ofType(ShellActionTypes.BOOTSTRAP_STARTED),
      flatMap(() => {
         fetch('/confighttps.json')
            .then((res) => res.json())
            .then((value: Partial<Features>) => {
               if (!!value.analytics) {
                  AnalyticsService.init(value.analyticsToken, value.analyticsUrl)
                  AnalyticsService.start()

                  window.addEventListener('beforeunload', () => {
                     AnalyticsService.stop()
                  })
               }
            })
            .catch((err) => {
               logger.debug('Error starting AnalyticsService.', err)
            })

         const unlisten = deps.history.listen(({action, location}) => {
            if (AnalyticsService.isInitialized()) {
               try {
                  AnalyticsService.track({
                     event: 'Page View',
                     properties: {
                        url: location.pathname,
                        searchParams: location.search,
                     },
                  })
               } catch (err) {
                  logger.debug('Error adding to AnalyticsService queue.', err)
               }
            }
         })

         return action$.pipe(
            ofType(ShellActionTypes.DISPOSE_STARTED),
            tap(() => {
               unlisten()
            }),
            ignoreElements(),
         )
      }),
   )
