import {observableXhrUpload, UploadProgress} from '@uieng/messaging-api'
import {Observable} from 'rxjs'

export interface UploadClient {
   upload(url: string, options: RequestInit): Observable<UploadProgress>
}

export class DefaultUploadClient implements UploadClient {
   upload(url: string, options: RequestInit): Observable<UploadProgress> {
      return observableXhrUpload(url, options)
   }
}
