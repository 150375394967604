import {ActionsUnion, createAction} from '@martin_hotell/rex-tils'
import {AlertOptions} from './model/notifications'

export enum NotificationActionTypes {
   SHOW_ALERT = '[notifications] SHOW_ALERT',
}

export const NotificationActions = {
   showAlert: (options: AlertOptions) => createAction(NotificationActionTypes.SHOW_ALERT, options),
}

export type NotificationActions = ActionsUnion<typeof NotificationActions>
