import {CellRendererProps} from '@ag-grid-community/core'
import {ICellRendererReactComp} from '@ag-grid-community/react'
import React from 'react'
import {FileEntry} from '../../../../directory-list-api'
import styles from './styles.module.scss'

export interface FileNameRendererExtraParams {
   onFileNameClick: (entry: FileEntry) => void
}

interface Props extends CellRendererProps<FileEntry>, FileNameRendererExtraParams {}

export class FileNameRenderer extends React.Component<Props> implements ICellRendererReactComp {
   refresh(params: Props): boolean {
      return false
   }

   render() {
      const entry = this.props.data
      const label = this.props.value

      if (entry == null) {
         return null
      }

      if (entry.directory === true) {
         return (
            <button className={styles.linkButton} onClick={this._onClick}>
               <i className="gsi gsi-folder-open" /> {label}
            </button>
         )
      }

      if (entry.file === true) {
         return (
            <button className={styles.linkButton} onClick={this._onClick}>
               <i className="gsi gsi-file" /> {label}
            </button>
         )
      }

      if (entry.link === true) {
         return (
            <button className={styles.linkButton} onClick={this._onClick}>
               <i className="gsi gsi-folder-open" /> {label}
            </button>
         )
      }

      return <span>Unsupported type: {label}</span>
   }

   private _onClick = () => {
      this.props.onFileNameClick(this.props.data!)
   }
}
