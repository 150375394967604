import React from 'react'
import {UnauthorizedView} from './unauthorizedView'

const RenderUnauthorizedContainer: React.FC = () => {
   return (
      <div>
         <UnauthorizedView show={true} />
      </div>
   )
}

export const UnauthorizedContainer = React.memo(RenderUnauthorizedContainer)
