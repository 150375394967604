import {analyticsEpic} from './analyticsEpic'
import {bootstrapShellModuleEpic} from './bootstrapShellModule'
import {combineEpics} from 'redux-observable'
import {confirmBrowserCloseEpic} from './confirmBrowserCloseEpic'
import {disposeShellModule} from './disposeShellModule'
import {getUserIpAddressEpic} from './getUserIpAddressEpic'
import {notificationsEpic} from './notificationsEpic'

export const shellEpic = combineEpics(
   analyticsEpic,
   bootstrapShellModuleEpic,
   notificationsEpic,
   confirmBrowserCloseEpic,
   disposeShellModule,
   getUserIpAddressEpic,
)
