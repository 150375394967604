import {PolimerDependencies} from '@uieng/polimer-js'
import {RoutingDependencies} from '@uieng/polimer-js-routing'
import {RestMessagingClient} from '../../messaging'
import {
   DefaultAuthenticationReactiveClient,
   DefaultFilesManagementReactiveClient,
   DefaultUserProfileReactiveClient,
} from '../../messaging-generated-code'
import {DefaultUserProfileService, SimpleBrowserApi} from '../../services'
import {ApplicationConfiguration, EpicDependencies} from '../../system-api'

export const createContainer = (
   config: ApplicationConfiguration,
): Omit<EpicDependencies, keyof PolimerDependencies | keyof RoutingDependencies> => {
   const messagingClient = new RestMessagingClient(config.requestTimeoutMs)
   messagingClient.initialise(config.apiUriPrefix)

   return {
      restMessagingClient: messagingClient,
      authenticationClient: new DefaultAuthenticationReactiveClient(messagingClient),
      filesManagementClient: new DefaultFilesManagementReactiveClient(messagingClient),
      userProfileService: new DefaultUserProfileService(new DefaultUserProfileReactiveClient(messagingClient)),
      browserApi: new SimpleBrowserApi(config.authentication.redirectUrl),
   }
}
