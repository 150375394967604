import {ActionsUnion, createAction} from '@martin_hotell/rex-tils'
import {ResponseWrapper} from '@uieng/messaging-api'
import {ApplicationConfiguration} from '../system-api'

export enum ShellActionTypes {
   BOOTSTRAP_STARTED = '[shell] BOOTSTRAP_STARTED',
   BOOTSTRAP_FINISHED = '[shell] BOOTSTRAP_FINISHED',

   GET_USER_IP_ADDRESS = '[shell] GET_USER_IP_ADDRESS',
   GET_USER_IP_ADDRESS_RESULT = '[shell] GET_USER_IP_ADDRESS_RESULT',

   DISPOSE_STARTED = '[shell] DISPOSE_STARTED',
   DISPOSE_FINISHED = '[shell] DISPOSE_FINISHED',
}

export const ShellActions = {
   bootstrapStarted: (config: ApplicationConfiguration) => createAction(ShellActionTypes.BOOTSTRAP_STARTED, {config}),
   bootstrapFinished: () => createAction(ShellActionTypes.BOOTSTRAP_FINISHED),

   getUserIpAddress: () => createAction(ShellActionTypes.GET_USER_IP_ADDRESS),
   getUserIpAddressResult: (result: ResponseWrapper<void, string>) =>
      createAction(ShellActionTypes.GET_USER_IP_ADDRESS_RESULT, result),

   disposeStarted: () => createAction(ShellActionTypes.DISPOSE_STARTED),
   disposeFinished: () => createAction(ShellActionTypes.DISPOSE_FINISHED),
}

export type ShellActions = ActionsUnion<typeof ShellActions>
