import {ofType} from '@martin_hotell/rex-tils'
import {getLogger} from '@uieng/logger'
import {ActionsObservable, combineEpics} from 'redux-observable'
import {merge} from 'rxjs'
import {filter, flatMap, map, takeUntil} from 'rxjs/operators'
import {FileUploadEpic} from '..'
import {DirectoryListActions} from '../../directory-list-api'
import {FileUploadActions, FileUploadActionTypes, UploadDescriptor, UploadState} from '../../file-upload-api'
import {AlertLevel, NotificationActions} from '../../shell-api'
import {AllActions} from '../../system-api'
import {FileUploadService} from '../services/fileUploadService'
import {generateFileUploadFailedMessage, generateFileUploadSuccessMessage} from './notificationMessages'

const logger = getLogger('FileUploadLogger')

const createUpload$ = (
   service: FileUploadService,
   descriptor: UploadDescriptor,
   action$: ActionsObservable<AllActions>,
) =>
   service.upload({descriptor: descriptor}).pipe(
      takeUntil(
         action$.pipe(
            ofType(FileUploadActionTypes.CANCEL_FILE_UPLOAD),
            filter((action) => action.payload === descriptor.id),
         ),
      ),
      map(FileUploadActions.uploadFilesResult),
   )

/**
 * This is the epic that listens to every upload request sent by the user.
 *
 * It's purpose is to manage the queue mechanism of uploads. If the number of requested uploads + current active uploads
 * exceeds the maximum concurrent upload no, then this epic will split requested files and put them into a queue.
 */
const filesUploadQueueManagerEpic: FileUploadEpic = (action$, state$) =>
   action$.pipe(
      ofType(FileUploadActionTypes.REQUEST_FILES_UPLOAD),
      map((action) => {
         const maxUploads = state$.value.shell.config?.fileUpload.maxConcurrentUploads ?? 1
         const uploadState = state$.value.fileUpload
         const requestedFiles = action.payload.concat() // concat() as we're mutating this array here
         const currentQueue = uploadState.queuedUpUploadsBucket

         if (requestedFiles.length === 0) {
            logger.debug('Requested to upload [0] files. Aborting.')

            return []
         }

         logger.debug(`Requested to upload [${requestedFiles.length}] files.`)

         const enqueuedFileIds = currentQueue.toMapOf((item) => item.id)
         const filesToStartNum = maxUploads - uploadState.activeUploadsBucket.length
         const filesToEnqueue = requestedFiles
            .truncateLeft(filesToStartNum)
            .removeWithSelector((itemToEnqueue) => enqueuedFileIds[itemToEnqueue.id] != null)

         let actions: AllActions[] = []

         if (filesToStartNum > 0) {
            logger.debug(`Starting file upload for [${filesToStartNum}] files now.`)

            actions.push(FileUploadActions.uploadFiles(requestedFiles))
         }

         if (filesToEnqueue.length > 0) {
            logger.debug(
               `Adding [${filesToEnqueue.length}] files to queue as max concurrent uploads limit have been reached.`,
            )

            actions.push(FileUploadActions.enqueueFilesUpload(filesToEnqueue))
         }

         return actions
      }),
   )

/**
 * This epic is responsible for kicking off the upload stream.
 */
const doUploadFileEpic: FileUploadEpic = (action$, state$, deps) =>
   action$.pipe(
      ofType(FileUploadActionTypes.UPLOAD_FILES),
      flatMap((action) => {
         logger.debug(`Starting upload stream for [${action.payload.length}] files.`)

         const filesUpload$ = action.payload.map((desc) => createUpload$(deps.uploadService, desc, action$))

         return merge(...filesUpload$)
      }),
   )

const fileUploadFailedEpic: FileUploadEpic = (action$, state$) =>
   action$.pipe(
      ofType(FileUploadActionTypes.UPLOAD_FILES_RESULT),
      filter((action) => action.payload.error != null),
      map((action) => {
         const result = action.payload
         const fileName = result.request.descriptor.file.name

         return [
            NotificationActions.showAlert({
               level: AlertLevel.Error,
               message: generateFileUploadFailedMessage(result.error!, fileName),
            }),
            FileUploadActions.requestFilesUpload(state$.value.fileUpload.queuedUpUploadsBucket),
         ]
      }),
   )

const fileUploadSuccessEpic: FileUploadEpic = (action$, state$) =>
   action$.pipe(
      ofType(FileUploadActionTypes.UPLOAD_FILES_RESULT),
      filter((action) => action.payload.response?.state === UploadState.Completed),
      map((action) => {
         const fileName = action.payload.request.descriptor.file.name
         const absolutePath = action.payload.request.descriptor.targetDirectory

         return [
            NotificationActions.showAlert({
               level: AlertLevel.Success,
               message: generateFileUploadSuccessMessage(fileName),
            }),
            DirectoryListActions.listFilesRequested(true, absolutePath),
            FileUploadActions.requestFilesUpload(state$.value.fileUpload.queuedUpUploadsBucket),
         ]
      }),
   )

const restartUploadOnCancel: FileUploadEpic = (action$, state$) =>
   action$.pipe(
      ofType(FileUploadActionTypes.CANCEL_FILE_UPLOAD),
      map(() => FileUploadActions.requestFilesUpload(state$.value.fileUpload.queuedUpUploadsBucket)),
   )

export const uploadFileEpic = combineEpics(
   doUploadFileEpic,
   fileUploadFailedEpic,
   fileUploadSuccessEpic,
   filesUploadQueueManagerEpic,
   restartUploadOnCancel,
)
