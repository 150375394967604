import {ofType} from '@martin_hotell/rex-tils'
import {mapToNonNullable} from '@uieng/common'
import {combineEpics} from 'redux-observable'
import {flatMap, map} from 'rxjs/operators'
import {AlertLevel, NotificationActions} from '../../shell-api'
import {ApplicationEpic} from '../../system-api'
import {UserProfileActions, UserProfiledActionTypes} from '../../user-profile-api'
import {updateUserProfileFailedMessage} from './notificationMessages'

const doUpdateDetailsEpic: ApplicationEpic = (action$, state$, deps) =>
   action$.pipe(
      ofType(UserProfiledActionTypes.UPDATE_PROFILE_DETAILS),
      flatMap((action) => {
         const request = action.payload
         const accountName = state$.value.userProfile.profileDetailsResponse.data?.accountName ?? ''

         return deps.userProfileService.updateProfileDetails({
            accountName: accountName,
            contactName: request.name,
            contactEmail: request.email,
         })
      }),
      map(UserProfileActions.updateProfileDetailsResult),
   )

const updateUserProfileFailedEpic: ApplicationEpic = (action$) =>
   action$.pipe(
      ofType(UserProfiledActionTypes.UPDATE_PROFILE_DETAILS_RESULT),
      mapToNonNullable((action) => action.payload.error),
      map((error) =>
         NotificationActions.showAlert({
            level: AlertLevel.Error,
            message: updateUserProfileFailedMessage(error),
         }),
      ),
   )

export const updateDetailsEpic = combineEpics(doUpdateDetailsEpic, updateUserProfileFailedEpic)
