import {ofType} from '@martin_hotell/rex-tils'
import {flatMap, ignoreElements, tap} from 'rxjs/operators'
import {ShellEpic} from '..'
import {UploadState} from '../../file-upload-api'
import {ShellActionTypes} from '../../shell-api'
import {ApplicationGlobalState} from '../../system-api'

const isPromptActive = (state: ApplicationGlobalState) => {
   const uploadInProgress = state.fileUpload.entries.some(
      (entry) => entry.state !== UploadState.Completed && entry.state !== UploadState.Aborted,
   )

   return uploadInProgress
}

export const confirmBrowserCloseEpic: ShellEpic = (action$, state$) =>
   action$.pipe(
      ofType(ShellActionTypes.BOOTSTRAP_STARTED),
      flatMap(() => {
         const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (isPromptActive(state$.value)) {
               event.returnValue = 'Changes you made may not be saved.'
            }
         }

         window.addEventListener('beforeunload', handleBeforeUnload)

         return action$.pipe(
            ofType(ShellActionTypes.DISPOSE_STARTED),
            tap(() => {
               window.removeEventListener('beforeunload', handleBeforeUnload)
            }),
            ignoreElements(),
         )
      }),
   )
