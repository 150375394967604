import {ofType} from '@martin_hotell/rex-tils'
import {RoutingActionTypes} from '@uieng/polimer-js-routing'
import {filter, map} from 'rxjs/operators'
import {WorkspaceRoutes} from '../../shell-api'
import {ApplicationEpic} from '../../system-api'
import {UserProfileActions} from '../../user-profile-api'

export const userProfileRouteMadeVisible: ApplicationEpic = (action$) =>
   action$.pipe(
      ofType(RoutingActionTypes.ROUTE_MADE_VISIBLE),
      filter((action) => action.payload.routeName === WorkspaceRoutes.UserProfile),
      map(UserProfileActions.getProfileDetails),
   )
