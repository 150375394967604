import {bootstrapAuthModuleEpic} from './bootstrapAuthModuleEpic'
import {combineEpics} from 'redux-observable'
import {loginEpic} from './loginEpic'
import {logoutEpic} from './logoutEpic'
import {redirectToDirectoryListEpic} from './redirectToDirectoryListEpic'
import {handleUnauthenticated} from './redirectToLoginEpic'
import {redirectToUnauthorizedEpic} from './redirectToUnauthorizedEpic'

export const authenticationEpic = combineEpics(
   loginEpic,
   logoutEpic,
   bootstrapAuthModuleEpic,
   handleUnauthenticated,
   redirectToDirectoryListEpic,
   redirectToUnauthorizedEpic,
)
