import {ofType} from '@martin_hotell/rex-tils'
import {RoutingActions} from '@uieng/polimer-js-routing'
import {map} from 'rxjs/operators'
import {WorkspaceRoutes} from '../../shell-api'
import {ApplicationEpic} from '../../system-api'
import {UserProfiledActionTypes} from '../../user-profile-api'

export const navigateBackEpic: ApplicationEpic = (action$) =>
   action$.pipe(
      ofType(UserProfiledActionTypes.GO_BACK),
      map(() => RoutingActions.navigateToRoute(WorkspaceRoutes.DirectoryList)),
   )
