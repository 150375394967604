export enum ApplicaitonModuleKeys {
   Routing = 'routing',
   Authentication = 'auth',
   Shell = 'shell',
   DirectoryList = 'directoryList',
   FileUpload = 'fileUpload',
   ResetPassword = 'resetPassword',
   UserProfile = 'userProfile',
   Unauthorized = 'unauthorized',
}
