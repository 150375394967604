import {ToastService, ToastStatus} from '@gs-ux-uitoolkit-react/toast'
import {assertUnreachable} from '@uieng/core'
import {AlertLevel, AlertOptions} from '../../shell-api'

export interface NotificationService {
   showAlert(options: AlertOptions): void
}

const defaultToastTimeoutMs = 5_000

export class DefaultNotificationService implements NotificationService {
   constructor(private readonly _toastService: ToastService) {}

   showAlert(options: AlertOptions): void {
      this._toastService.show({
         autoDismiss: defaultToastTimeoutMs,
         message: options.message,
         status: this._mapLevelToState(options.level),
      })
   }

   private _mapLevelToState(level: AlertLevel): ToastStatus {
      switch (level) {
         case AlertLevel.Error:
            return 'error'
         case AlertLevel.Success:
            return 'success'
         default:
            return assertUnreachable(level)
      }
   }
}
