import {AnalyticsObject, AnalyticsTrackEvent} from '../../shell-api'

export class AnalyticsService {
   private static initialized: boolean = false
   private static queue: Omit<AnalyticsObject, 'token'>[] = []
   private static token: string
   private static collectorEndpoint: string
   private static maxQueueLength = 200
   private static intervalId: number | undefined

   private static getTimestamp() {
      const date = new Date()
      date.setMilliseconds(0)

      return date.toISOString()
   }

   /**
    * Sends the data in the queue as a batch to the batch collector endpoint. If successful, clears the queue.
    */
   private static send() {
      const $this = this

      if (!!this.token && !!this.collectorEndpoint) {
         const body: AnalyticsObject[] = this.queue.map((i) => ({...i, token: this.token}))
         const url = new URL(this.collectorEndpoint)

         fetch(this.collectorEndpoint, {
            method: 'POST',
            body: JSON.stringify(body),
            credentials: 'include',
            headers: {
               'Content-Type': 'application/json',
               'Content-Security-Policy': `connect-src 'self' https://${url.hostname}`,
            },
         })
            .then(function (response) {
               if (response.status >= 200 && response.status < 300) {
                  $this.queue = []
               } else {
                  $this.stop()
               }
            })
            .catch((err) => {
               $this.stop()
            })
      }
   }

   public static init(token?: string, collectorEndpoint?: string) {
      if (!this.initialized) {
         if (!token) {
            throw new Error('Missing token in AnalyticsService init.')
         }

         this.token = token

         if (!collectorEndpoint) {
            throw new Error('Missing collectorEndpoint in AnalyticsService init.')
         }

         this.collectorEndpoint = collectorEndpoint

         this.initialized = true
      }
   }

   /**
    * Starts processing the queue (stops any existing queues).
    * @param intervalMs How long to wait before sending the next batch of objects in the queue (default 3000ms).
    * Note that the GS Analytics service has a limit of 200 events per second.
    */
   public static start(intervalMs = 3000) {
      // before starting interval, stop existing interval if any
      if (!!this.intervalId) this.stop()

      this.intervalId = window.setInterval(() => {
         if (this.queue.length >= 1) this.send()
      }, intervalMs)
   }

   /**
    * Stops processing the queue.
    */
   public static stop() {
      window.clearInterval(this.intervalId)
      this.intervalId = undefined
   }

   /**
    * Adds an event to the queue.
    * @param event Object containing the event metadata.
    */
   public static track(event: AnalyticsTrackEvent) {
      if (this.queue.length < this.maxQueueLength) {
         this.queue.push({
            ...event,
            properties: {...(event.properties || {}), baseUrl: window.location.host},
            timestamp: this.getTimestamp(),
         })
      } else {
         console.log('Error adding analytics event to queue. Queue length exceeded.')
      }
   }

   public static isInitialized() {
      return this.initialized
   }
}
