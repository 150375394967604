import {Breadcrumb, BreadcrumbItem, Button, Container} from '@gs-ux-uitoolkit-react/core'
import {ResponseDefinition, ResponseState} from '@uieng/messaging-api'
import cn from 'classnames'
import React, {ChangeEvent, useCallback, useRef} from 'react'
import {DeleteFilesResponse, FileEntry, ListFilesResponse} from '../../directory-list-api'
import {DataGrid} from './grid/grid'
import styles from './styles.module.scss'
import {isInternalChinaMode} from '../../shell-api'

interface Props {
   currentPath: string[]
   listFilesResponse: ResponseDefinition<ListFilesResponse>
   deleteFilesResponse: ResponseDefinition<DeleteFilesResponse>

   onRefreshClick: () => void
   onBreadcrumbClick: (index: number) => void
   onFileNameClick: (entry: FileEntry) => void
   onDeleteClick: (entry: FileEntry) => void
   onFilesUpload: (files: FileList) => void
}

interface BreadcrumbProps {
   index: number
   isLast: boolean
   label: string
   enabled: boolean
   onBreadcrumbClick: (index: number) => void
}

const RenderBreadcrumb: React.FC<BreadcrumbProps> = ({index, isLast, label, enabled, onBreadcrumbClick}) => {
   const handleClick = useCallback(() => {
      onBreadcrumbClick(index)
   }, [index, onBreadcrumbClick])

   if (isLast === true) {
      return (
         <BreadcrumbItem className={styles.breadcrumbItem} active={true}>
            {label}
         </BreadcrumbItem>
      )
   }

   return (
      <BreadcrumbItem className={styles.breadcrumbItem} active={true}>
         <button disabled={!enabled} className={styles.breadcrumbLink} onClick={handleClick}>
            {label}
         </button>
      </BreadcrumbItem>
   )
}

const RenderDirectoryListView: React.FC<Props> = (props) => {
   const {onFilesUpload} = props
   const uploadInputRef = useRef<HTMLInputElement>(null)
   const handleUploadChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
         if (event.target.files != null) {
            onFilesUpload(event.target.files)

            if (uploadInputRef.current != null) {
               uploadInputRef.current.value = ''
            }
         }
      },
      [uploadInputRef, onFilesUpload],
   )

   const handleUploadClick = useCallback(() => {
      uploadInputRef.current?.click()
   }, [uploadInputRef])

   const isLoading =
      props.listFilesResponse.state === ResponseState.Loading ||
      props.deleteFilesResponse.state === ResponseState.Loading

   const breadcrumbs = props.currentPath.map((element, i) => {
      const key = `${element}_${i}`

      // Last element typically is non-clickable, but we want the user to be able to click on "Home"
      // even if it's the only breadcrumb
      const isLast = i === props.currentPath.length - 1 && i !== 0
      const label = i === 0 ? 'Home' : element

      return (
         <RenderBreadcrumb
            key={key}
            index={i}
            isLast={isLast}
            enabled={isLoading === false}
            label={label}
            onBreadcrumbClick={props.onBreadcrumbClick}
         />
      )
   })

    const supportMulti = !isInternalChinaMode()

   return (
      <Container className={styles.directoryListRootContainer}>
         <div className={styles.headerContainer}>
            <Breadcrumb>{breadcrumbs}</Breadcrumb>

            <div className={styles.headerButtonsContainer}>
               <input
                  ref={uploadInputRef}
                  type="file"
                  name="file"
                  multiple={supportMulti}
                  onChange={handleUploadChange}
                  style={{display: 'none'}}
               />

               <Button color="primary" size="sm" disabled={isLoading} onClick={handleUploadClick}>
                  <i className={cn(styles.smallIcon, 'gsi gsi-upload')} /> Upload
               </Button>

               <Button color="primary" size="sm" disabled={isLoading} onClick={props.onRefreshClick}>
                  <i className={cn(styles.smallIcon, 'gsi gsi-refresh')} /> Refresh
               </Button>
            </div>
         </div>

         <DataGrid
            className={styles.dataGridContainer}
            data={props.listFilesResponse.data?.entries ?? []}
            loading={isLoading}
            onFileNameClick={props.onFileNameClick}
            onDeleteClick={props.onDeleteClick}
         />
      </Container>
   )
}

export const DirectoryListView = React.memo(RenderDirectoryListView)
