import {ModuleApi, PolimerEpic} from '@uieng/polimer-js'
import {FileUploadState} from '../file-upload-api'
import {WorkspaceRoutes} from '../shell-api'
import {
   AllActions,
   ApplicaitonModuleKeys,
   ApplicationConfiguration,
   ApplicationGlobalState,
   EpicDependencies,
} from '../system-api'
import {fileUploadEpic} from './epic'
import {fileUploadReducer} from './reducer'
import {DefaultFileUploadService, FileUploadService} from './services/fileUploadService'
import {DefaultUploadClient} from './services/uploadClient'
import {FileUploadContainer} from './view/fileUploadContainer'

export interface FileUploadDependencies {
   uploadService: FileUploadService
}

export type FileUploadEpic = PolimerEpic<
   AllActions,
   AllActions | AllActions[],
   ApplicationGlobalState,
   FileUploadDependencies & EpicDependencies
>

export const fileUploadModuleApi: ModuleApi<ApplicationGlobalState, FileUploadState, void, FileUploadDependencies> = {
   moduleId: '',
   moduleKey: ApplicaitonModuleKeys.FileUpload,
   reducer: fileUploadReducer,
   epic: fileUploadEpic,
   defaultViewContainer: FileUploadContainer,
   dependencies: (container: EpicDependencies, config: ApplicationConfiguration) => {
      const uploadService = new DefaultFileUploadService(container.browserApi, new DefaultUploadClient())
      uploadService.initialise(config.apiUriPrefix)

      return {
         uploadService: uploadService,
      }
   },
   routingConfig: {
      route: '',
      loadOnStartup: true,
      routeName: WorkspaceRoutes.FileUpload,
   },
}
