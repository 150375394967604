import {ActionsUnion, createAction} from '@martin_hotell/rex-tils'
import {ResponseWrapper} from '@uieng/messaging-api'
import {ApplicationConfiguration} from '../system-api'
import {FileEntry} from './model/fileEntry'
import {DeleteFilesRequest, ListFilesRequest} from './requests'
import {DeleteFilesResponse, ListFilesResponse} from './responses'

export enum DirectoryListActionTypes {
   BOOTSTRAP_STARTED = '[directory-list] BOOTSTRAP_STARTED',
   BOOTSTRAP_FINISHED = '[directory-list] BOOTSTRAP_FINISHED',

   LIST_FILES_REQUESTED = '[directory-list] LIST_FILES_REQUESTED',
   LIST_FILES = '[directory-list] LIST_FILES',
   LIST_FILES_RESULT = '[directory-list] LIST_FILES_RESULT',
   ABORT_LIST_FILES = '[directory-list] ABORT_LIST_FILES',

   DELETE_FILE_REQUESTED = '[directory-list] DELETE_FILE_REQUESTED',
   DELETE_FILE = '[directory-list] DELETE_FILE',
   DELETE_FILE_RESULT = '[directory-list] DELETE_FILE_RESULT',

   UPLOAD_FILES_REQUESTED = '[directory-list] UPLOAD_FILES_REQUESTED',

   BREADCRUMB_CLICK = '[directory-list] BREADCRUMB_CLICK',
   FILE_SELECTED = '[directory-list] FILE_SELECTED',
   UPDATE_CURRENT_PATH = '[directory-list] UPDATE_CURRENT_PATH',
   EXPIRE_PATH_FROM_CACHE = '[directory-list] EXPIRE_PATH_FROM_CACHE',
}

export const DirectoryListActions = {
   bootstrapStarted: (config: ApplicationConfiguration) =>
      createAction(DirectoryListActionTypes.BOOTSTRAP_STARTED, config),
   bootstrapFinished: () => createAction(DirectoryListActionTypes.BOOTSTRAP_FINISHED),

   listFilesRequested: (force: boolean = false, absolutePath?: string) =>
      createAction(DirectoryListActionTypes.LIST_FILES_REQUESTED, {force, absolutePath}),
   listFiles: (request: ListFilesRequest) => createAction(DirectoryListActionTypes.LIST_FILES, request),
   listFilesResult: (result: ResponseWrapper<ListFilesRequest, ListFilesResponse>) =>
      createAction(DirectoryListActionTypes.LIST_FILES_RESULT, result),
   abortListFiles: () => createAction(DirectoryListActionTypes.ABORT_LIST_FILES),

   deleteFileRequested: (entry: FileEntry) => createAction(DirectoryListActionTypes.DELETE_FILE_REQUESTED, entry),
   deleteFile: (entry: FileEntry) => createAction(DirectoryListActionTypes.DELETE_FILE, entry),
   deleteFileResult: (result: ResponseWrapper<DeleteFilesRequest, DeleteFilesResponse>) =>
      createAction(DirectoryListActionTypes.DELETE_FILE_RESULT, result),

   uploadFilesRequested: (files: FileList) => createAction(DirectoryListActionTypes.UPLOAD_FILES_REQUESTED, files),

   breadcrumbClick: (index: number) => createAction(DirectoryListActionTypes.BREADCRUMB_CLICK, index),
   fileSelected: (entry: FileEntry) => createAction(DirectoryListActionTypes.FILE_SELECTED, entry),
   updateCurrentPath: (path: string[], updateQueryParams: boolean) =>
      createAction(DirectoryListActionTypes.UPDATE_CURRENT_PATH, {path, updateQueryParams}),
   expirePathFromCache: (absolutePath: string) =>
      createAction(DirectoryListActionTypes.EXPIRE_PATH_FROM_CACHE, absolutePath),
}

export type DirectoryListActions = ActionsUnion<typeof DirectoryListActions>
