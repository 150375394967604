import {FileUploadActions, FileUploadEntry, ViewState} from '../../file-upload-api'
import {useDispatch, useSelector} from 'react-redux'

import {ApplicationGlobalState} from '../../system-api'
import {AttestationView} from './attestationView'
import {FileUploadView} from './fileUploadView'
import React from 'react'
import {useActionCallback} from '@uieng/polimer-js'

interface StoreProps {
   entries: FileUploadEntry[]
   viewState: ViewState
   showAttestationModal: boolean
}

const RenderFileUploadContainer: React.FC = () => {
   const storeProps = useSelector<ApplicationGlobalState, StoreProps>((store) => ({
      entries: store.fileUpload.entries,
      viewState: store.fileUpload.viewState,
      showAttestationModal: store.fileUpload.showAttestationModal,
   }))

   const dispatch = useDispatch()
   const onClearClick = useActionCallback(FileUploadActions.clearUploadList, dispatch)
   const onCancelClick = useActionCallback(FileUploadActions.cancelFileUpload, dispatch)
   const onViewStateChanged = useActionCallback(FileUploadActions.setViewState, dispatch)
   const onAttestationSubmit = useActionCallback(FileUploadActions.setFileUploadAttestation, dispatch)
   const onAttestationCancel = useActionCallback(FileUploadActions.hideFileUploadAttestation, dispatch)

   return (
      <div>
         <AttestationView
            show={storeProps.showAttestationModal}
            onSubmit={onAttestationSubmit}
            onCancel={onAttestationCancel}
         />
         <FileUploadView
            viewState={storeProps.viewState}
            entries={storeProps.entries}
            onClearClick={onClearClick}
            onCancelClick={onCancelClick}
            onViewStateChanged={onViewStateChanged}
         />
      </div>
   )
}

export const FileUploadContainer = React.memo(RenderFileUploadContainer)
