import {ChangePasswordRequestDto} from '../../../messaging-generated-code'
import {ChangePasswordRequest} from '../../userProfileService'

export const changePasswordRequestMapper = (request: ChangePasswordRequest): ChangePasswordRequestDto => {
   return {
      accountName: request.username,
      oldPassword: request.currentPassword,
      newPassword: request.newPassword,
   }
}
