import {WorkspaceQueryParams, isInternalChinaMode} from '../../shell-api'
import {useDispatch, useSelector} from 'react-redux'

import {ApplicationGlobalState} from '../../system-api'
import {AuthActions} from '../../authentication-api'
import {LoginView} from './loginView'
import React from 'react'
import {ResponseState} from '@uieng/messaging-api'
import {getQueryParam} from '@uieng/polimer-js-routing'
import {useActionCallback} from '@uieng/polimer-js'

interface StoreProps {
   loginResponseState: ResponseState
   isAuthenticated: boolean
   isSessionTimedOut: boolean
   isPasswordChanged: boolean
   data?: string
}

const RenderLoginContainer: React.FC = () => {
   const storeProps = useSelector<ApplicationGlobalState, StoreProps>((store) => ({
      loginResponseState: store.auth.loginResponse.state,
      isAuthenticated: store.auth.isAuthenticated,
      isSessionTimedOut: getQueryParam(store.routing, WorkspaceQueryParams.SessionTimeout)?.value === 'true' ?? false,
      isPasswordChanged: getQueryParam(store.routing, WorkspaceQueryParams.PasswordChanged)?.value === 'true' ?? false,
      data: store.auth.error,
   }))

   const dispatch = useDispatch()

   const onLoginSubmit = useActionCallback(AuthActions.login, dispatch)
   const onGSSSOLogin = useActionCallback(AuthActions.gsssoLogin, dispatch)

   React.useEffect(() => {
      const has_gssso = document.cookie.indexOf('GSSSO') > -1

      if (has_gssso && !storeProps.isAuthenticated && isInternalChinaMode()) {
         onGSSSOLogin()
      }

      // eslint-disable-next-line
   }, [storeProps.isAuthenticated])

   React.useEffect(() => {
      if (storeProps.isPasswordChanged) {
         alert('Your passsword has been changed. Please login again.')
         window.location.replace('/login')
      }
   }, [storeProps.isPasswordChanged])

   return (
      <LoginView
         loginResponseState={storeProps.loginResponseState}
         isSessionTimedOut={storeProps.isSessionTimedOut}
         onSubmit={onLoginSubmit}
         error={storeProps.data}
      />
   )
}

export const LoginContainer = React.memo(RenderLoginContainer)
