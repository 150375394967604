import {CellRendererProps} from '@ag-grid-community/core'
import {ICellRendererReactComp} from '@ag-grid-community/react'
import {Button, Popover, PopoverBody} from '@gs-ux-uitoolkit-react/core'
import React from 'react'
import {FileEntry} from '../../../../directory-list-api'
import styles from './styles.module.scss'

export interface ActionsRendererExtraParams {
   onDeleteClick: (entry: FileEntry) => void
}

interface Props extends CellRendererProps<FileEntry>, ActionsRendererExtraParams {}

interface State {
   isPopoverOpen: boolean
}

export class ActionsRenderer extends React.Component<Props, State> implements ICellRendererReactComp {
   private deleteButtonRef = React.createRef<any>()

   constructor(props: Props) {
      super(props)

      this.state = {
         isPopoverOpen: false,
      }
   }

   refresh(params: Props): boolean {
      return false
   }

   render() {
      const entry = this.props.data

      if (entry == null || entry.removable === false) {
         return null
      }

      return (
         <div className={styles.actionsContainer}>
            <button ref={this.deleteButtonRef} className={styles.linkButton} onClick={this._onPopoverToggle}>
               <i className="gsi gsi-delete" />
            </button>

            <Popover
               placement="top"
               isOpen={this.state.isPopoverOpen}
               target={this.deleteButtonRef}
               trigger="legacy"
               toggle={this._onPopoverToggle}
               hideArrow={true}
            >
               <PopoverBody>
                  <span>Are you sure you want to delete this file?</span>

                  <div className={styles.actionButtonsContainer}>
                     <Button color="tertiary" size="sm" onClick={this._onPopoverToggle}>
                        No
                     </Button>
                     <Button color="danger" size="sm" onClick={this._onDeleteClick}>
                        Yes
                     </Button>
                  </div>
               </PopoverBody>
            </Popover>
         </div>
      )
   }

   private _onDeleteClick = () => {
      this.props.onDeleteClick(this.props.data!)

      this._onPopoverToggle()
   }

   private _onPopoverToggle = () => {
      this.setState({isPopoverOpen: !this.state.isPopoverOpen})
   }
}
