import {AllActionTypes, AllActions} from '../../system-api'
import {AuthActionTypes, AuthenticationState} from '../../authentication-api'

import {ResponseState} from '@uieng/messaging-api'
import {createMutableReducer} from '@uieng/common'

const initialState: AuthenticationState = {
   loginResponse: {
      state: ResponseState.Idle,
   },
   logoutResponse: {
      state: ResponseState.Idle,
   },
   isAuthenticated: false,
}

export const isResetPasswordRequiredErr = (err: string) => err.includes('must reset password')

export const authenticationReducer = createMutableReducer<AllActionTypes, AllActions, AuthenticationState>(
   {
      [AuthActionTypes.LOGIN]: (draft) => {
         draft.loginResponse.state = ResponseState.Loading
      },
      [AuthActionTypes.LOGIN_RESULT]: (draft, action) => {
         const result = action.payload

         draft.loginResponse.state = result.error != null ? ResponseState.Error : ResponseState.Success

         if (!!result.error) {
            draft.error = result.error.message
         }
      },
      [AuthActionTypes.AUTHENTICATED]: (draft) => {
         draft.isAuthenticated = true
      },
      [AuthActionTypes.UNAUTHENTICATED]: (draft) => {
         draft.isAuthenticated = false
      },
      [AuthActionTypes.LOGOUT]: (draft) => {
         draft.logoutResponse.state = ResponseState.Loading
      },
      [AuthActionTypes.LOGOUT_RESULT]: (draft, action) => {
         const result = action.payload

         draft.logoutResponse.state = result.error != null ? ResponseState.Error : ResponseState.Success
      },
   },
   initialState,
)
