import {AllActionTypes, AllActions} from '../../system-api'
import {FileUploadActionTypes, FileUploadEntry, FileUploadState, UploadState, ViewState} from '../../file-upload-api'

import {createMutableReducer} from '@uieng/common'

const initialState: FileUploadState = {
   viewState: ViewState.Closed,
   entries: [],

   activeUploadsBucket: [],
   queuedUpUploadsBucket: [],

   showAttestationModal: false,
}

export const fileUploadReducer = createMutableReducer<AllActionTypes, AllActions, FileUploadState>(
   {
      [FileUploadActionTypes.SET_VIEW_STATE]: (draft, action) => {
         draft.viewState = action.payload
      },
      [FileUploadActionTypes.REQUEST_FILES_UPLOAD]: (draft, action) => {
         const descriptors = action.payload
         const entriesById = draft.entries.toMapOf((entry) => entry.id)
         const newDescriptors = descriptors.filter((item) => entriesById[item.id] == null)

         // Create upload entries only from the new descriptors to avoid duplicates
         // in case we re-request to upload the same descriptor (that's how the queue manager works)
         const newEntries: FileUploadEntry[] = newDescriptors.map((desc) => ({
            id: desc.id,
            label: desc.file.name,
            progress: 0,
            state: UploadState.Idle,
         }))

         draft.entries.push(...newEntries)
         draft.filesPendingAttestation = undefined
         draft.showAttestationModal = false
      },
      [FileUploadActionTypes.UPLOAD_FILES]: (draft, action) => {
         const descriptors = action.payload
         const descriptorsById = action.payload.toMapOf((item) => item.id)

         draft.activeUploadsBucket.push(...descriptors)
         draft.queuedUpUploadsBucket = draft.queuedUpUploadsBucket.removeWithSelector(
            (item) => descriptorsById[item.id] != null,
         )
      },
      [FileUploadActionTypes.ENQUEUE_FILES_UPLOAD]: (draft, action) => {
         draft.queuedUpUploadsBucket.push(...action.payload)
      },
      [FileUploadActionTypes.UPLOAD_FILES_RESULT]: (draft, action) => {
         const result = action.payload
         const entry = draft.entries.find((entry) => entry.id === result.request.descriptor.id)

         if (entry != null) {
            if (result.response != null) {
               entry.progress = result.response.progress
               entry.state = result.response.state
            } else if (result.error != null) {
               entry.state = UploadState.Aborted
            }

            // Cleanup active uploads bucket when the upload is stopped/completed.
            if (entry.state === UploadState.Completed || entry.state === UploadState.Aborted) {
               draft.activeUploadsBucket = draft.activeUploadsBucket.removeWithSelector((item) => item.id === entry.id)
            }
         }
      },
      [FileUploadActionTypes.CANCEL_FILE_UPLOAD]: (draft, action) => {
         const entry = draft.entries.find((entry) => entry.id === action.payload)

         if (entry != null) {
            entry.state = UploadState.Aborted
            draft.activeUploadsBucket = draft.activeUploadsBucket.removeWithSelector((item) => item.id === entry.id)
            draft.queuedUpUploadsBucket = draft.queuedUpUploadsBucket.removeWithSelector((item) => item.id === entry.id)
         }
      },
      [FileUploadActionTypes.CLEAR_UPLOAD_LIST]: (draft) => {
         draft.entries = draft.entries.filter(
            (entry) => entry.state !== UploadState.Completed && entry.state !== UploadState.Aborted,
         )
      },
      [FileUploadActionTypes.SHOW_FILE_UPLOAD_ATTESTATION]: (draft, action) => {
         draft.filesPendingAttestation = action.payload
         draft.showAttestationModal = true
      },
      [FileUploadActionTypes.HIDE_FILE_UPLOAD_ATTESTATION]: (draft) => {
         draft.filesPendingAttestation = undefined
         draft.showAttestationModal = false
      },
   },
   initialState,
)
