import {combineEpics} from 'redux-observable'
import {bootstrapDirectoryListModuleEpic} from './bootstrapDirectoryListModuleEpic'
import {deleteFilesEpic} from './deleteFilesEpic'
import {downloadFileEpic} from './downloadFileEpic'
import {listFilesEpic} from './listFilesEpic'
import {navigationEpic} from './navigationEpic'
import {uploadFilesEpic} from './uploadFilesEpic'

export const directoryListEpic = combineEpics(
   bootstrapDirectoryListModuleEpic,
   listFilesEpic,
   deleteFilesEpic,
   navigationEpic,
   uploadFilesEpic,
   downloadFileEpic,
)
