import {GridOptions} from '@ag-grid-community/core'
import {formatTime, TimeUnit} from '@uieng/common'
import {FileEntry} from '../../../directory-list-api'
import {ActionsRenderer, ActionsRendererExtraParams} from './renderers/actionsRenderer'
import {FileNameRenderer, FileNameRendererExtraParams} from './renderers/fileNameRenderer'

interface GridConfigOptions {
   onFileNameClick: (entry: FileEntry) => void
   onDeleteClick: (entry: FileEntry) => void
}

export const createGridConfig = (options: GridConfigOptions): GridOptions => {
   const fileNameProps: FileNameRendererExtraParams = {
      onFileNameClick: options.onFileNameClick,
   }
   const actionProps: ActionsRendererExtraParams = {
      onDeleteClick: options.onDeleteClick,
   }

   return {
      suppressCellSelection: true,
      suppressRowClickSelection: true,
      suppressDragLeaveHidesColumns: true,
      suppressContextMenu: true,
      suppressNoRowsOverlay: true,
      immutableData: true,
      getRowNodeId: (item: FileEntry) => item.id,
      pagination: true,
      paginationPageSize: 100,
      columnDefs: [
         {
            headerName: 'Name',
            sort: 'asc',
            sortable: true,
            suppressMenu: true,
            field: 'fileName',
            flex: 1,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            floatingFilterComponentParams: {suppressFilterButton: true},
            cellRendererFramework: FileNameRenderer,
            cellRendererParams: fileNameProps,
         },
         {
            headerName: 'Size (bytes)',
            field: 'size',
            sortable: true,
            suppressMenu: true,
            valueFormatter: (params) => {
               const data = params.data as FileEntry

               if (data.directory === true) {
                  return '-'
               }

               return params.value != null ? params.value.toLocaleString() : '-'
            },
         },
         {
            headerName: 'Last modified',
            field: 'lastModified',
            sortable: true,
            suppressMenu: true,
            width: 160,
            valueFormatter: (params) => (params.data ? formatTime(params.value, TimeUnit.Milliseconds) : ''),
         },
         {
            headerName: 'Delete',
            suppressMenu: true,
            width: 70,
            cellRendererFramework: ActionsRenderer,
            cellRendererParams: actionProps,
         },
      ],
   }
}
