import {ofType} from '@martin_hotell/rex-tils'
import {getQueryParam} from '@uieng/polimer-js-routing'
import {map} from 'rxjs/operators'
import {DirectoryListEpic} from '..'
import {DirectoryListActions, DirectoryListActionTypes} from '../../directory-list-api'
import {WorkspaceQueryParams} from '../../shell-api'

export const bootstrapDirectoryListModuleEpic: DirectoryListEpic = (action$, state$) =>
   action$.pipe(
      ofType(DirectoryListActionTypes.BOOTSTRAP_STARTED),
      map((action) => {
         const pathParam = getQueryParam(state$.value.routing, WorkspaceQueryParams.Path)
         const config = action.payload

         let initialLocation = config.directoryList.defaultLocation.concat()

         if (pathParam != null && pathParam.value != null) {
            const separator = config.directoryList.pathSeparator
            initialLocation = pathParam.value?.split(separator)
         }

         return [
            DirectoryListActions.updateCurrentPath(initialLocation, true),
            DirectoryListActions.listFilesRequested(),
            DirectoryListActions.bootstrapFinished(),
         ]
      }),
   )
