import {FileUploadActionTypes, FileUploadActions} from '../../file-upload-api'

import {DirectoryListActionTypes} from '../../directory-list-api'
import {DirectoryListEpic} from '..'
import {combineEpics} from 'redux-observable'
import {getCurrentAbsolutePath} from '../selectors'
import {isInternalChinaMode} from '../../shell-api'
import {map} from 'rxjs/operators'
import {ofType} from '@martin_hotell/rex-tils'
import {v4} from 'uuid'

const uploadFilesEpicDefault: DirectoryListEpic = (action$, state$) =>
   action$.pipe(
      ofType(DirectoryListActionTypes.UPLOAD_FILES_REQUESTED),
      map((action) => {
         const payload = Array.from(action.payload).map((file) => ({
            id: v4(),
            file: file,
            targetDirectory: getCurrentAbsolutePath(state$.value.directoryList),
         }))

         if (isInternalChinaMode()) {
            return FileUploadActions.showFileUploadAttestation(payload)
         }

         return FileUploadActions.requestFilesUpload(payload)
      }),
   )

const uploadFilesEpicChina: DirectoryListEpic = (action$, state$) =>
   action$.pipe(
      ofType(FileUploadActionTypes.SET_FILE_UPLOAD_ATTESTATION),
      map((action) => {
         const payload = state$.value.fileUpload.filesPendingAttestation!

         return FileUploadActions.requestFilesUpload(payload.map((i) => ({...i, attestation: action.payload})))
      }),
   )

export const uploadFilesEpic = combineEpics(uploadFilesEpicDefault, uploadFilesEpicChina)
