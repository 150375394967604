import {DeleteFilesResponse, DirectoryListActions, ListFilesResponse} from '../../directory-list-api'
import {useDispatch, useSelector} from 'react-redux'

import {ApplicationGlobalState} from '../../system-api'
import {DirectoryListView} from './directoryListView'
import React from 'react'
import {ResponseDefinition} from '@uieng/messaging-api'
import {useActionCallback} from '@uieng/polimer-js'

interface StoreProps {
   currentPath: string[]
   listFilesResponse: ResponseDefinition<ListFilesResponse>
   deleteFilesResponse: ResponseDefinition<DeleteFilesResponse>
   isAuthenticated: boolean
}

const RenderDirectoryListContainer: React.FC = () => {
   const storeProps = useSelector<ApplicationGlobalState, StoreProps>((store) => {
      return {
         currentPath: store.directoryList.currentPath,
         listFilesResponse: store.directoryList.listFilesResponse,
         deleteFilesResponse: store.directoryList.deleteFilesResponse,
         isAuthenticated: store.auth.isAuthenticated,
      }
   })

   const dispatch = useDispatch()
   const onBreadcrumbClick = useActionCallback(DirectoryListActions.breadcrumbClick, dispatch)
   const onFileNameClick = useActionCallback(DirectoryListActions.fileSelected, dispatch)
   const onDeleteClick = useActionCallback(DirectoryListActions.deleteFileRequested, dispatch)
   const onRefreshClick = useActionCallback(() => DirectoryListActions.listFilesRequested(true), dispatch)
   const onFilesUpload = useActionCallback(DirectoryListActions.uploadFilesRequested, dispatch)

   if (!storeProps.isAuthenticated) return null

   return (
      <DirectoryListView
         currentPath={storeProps.currentPath}
         listFilesResponse={storeProps.listFilesResponse}
         deleteFilesResponse={storeProps.deleteFilesResponse}
         onFileNameClick={onFileNameClick}
         onBreadcrumbClick={onBreadcrumbClick}
         onRefreshClick={onRefreshClick}
         onDeleteClick={onDeleteClick}
         onFilesUpload={onFilesUpload}
      />
   )
}

export const DirectoryListContainer = React.memo(RenderDirectoryListContainer)
