import {ApplicaitonModuleKeys, ApplicationGlobalState} from '../system-api'

import {ModuleApi} from '@uieng/polimer-js'
import {UnauthorizedContainer} from './view/unauthorizedContainer'
import {UnauthorizedState} from '../unauthorized-api'
import {WorkspaceRoutes} from '../shell-api'

export const unauthorizedModuleApi: ModuleApi<ApplicationGlobalState, UnauthorizedState> = {
   moduleId: '',
   moduleKey: ApplicaitonModuleKeys.Unauthorized,
   defaultViewContainer: UnauthorizedContainer,
   routingConfig: {
      routeName: WorkspaceRoutes.Unauthorized,
      route: '/unauthorized/',
   },
}
