import React from 'react'
import {isExternalChinaMode} from '../../shell-api'
import styles from './styles.module.scss'

interface Props {
   version: string
}

export const FooterView: React.FC<Props> = ({version}) => {
   return (
      <div className={styles.footerContainer}>
         <div className={styles.topContainer}>
            <span>Welcome to SFX (Secure File Xchange) – Secure File Transfer Platform.</span>
            {isExternalChinaMode() && (
               <>
                  <a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">
                     京ICP备2020037304号
                  </a>
                  <a href="https://www.goldmansachs.cn/#/privacy_policy" target="_blank" rel="noopener noreferrer">
                     Privacy Policy
                  </a>
               </>
            )}
            <span className={styles.versionLabel}>SFX UI {version}</span>
         </div>

         <div className={styles.divider} />
         <p>
            © Copyright 2015. Goldman Sachs. All rights reserved. Goldman, Sachs & Co. Member SIPC. Access to and use of
            this site is limited to authorized customers of Goldman, Sachs & Co., Goldman Sachs International and their
            affiliates and certain other authorized persons. System response and account access times may vary due to
            market conditions, system performance and other factors.
         </p>
      </div>
   )
}
