import {ofType} from '@martin_hotell/rex-tils'
import {filter, flatMap, ignoreElements} from 'rxjs/operators'
import {DirectoryListEpic} from '..'
import {DirectoryListActionTypes} from '../../directory-list-api'

export const downloadFileEpic: DirectoryListEpic = (action$, state$, deps) =>
   action$.pipe(
      ofType(DirectoryListActionTypes.FILE_SELECTED),
      filter((action) => action.payload.file === true),
      flatMap((action) => {
         return deps.filesService.downloadFile({
            file: action.payload.absolutePath,
         })
      }),
      ignoreElements(),
   )
