import {BrowserApi} from '@uieng/common'
import {getLogger} from '@uieng/logger'
import {requestResponseWrapper, ResponseWrapper} from '@uieng/messaging-api'
import {Observable} from 'rxjs'
import {map, tap} from 'rxjs/operators'
import {authenticationCheck} from '../../authentication-api'
import {
   DeleteFilesRequest,
   DeleteFilesResponse,
   DownloadFileRequest,
   ListFilesRequest,
   ListFilesResponse,
} from '../../directory-list-api'
import {FilesManagementReactiveClient} from '../../messaging-generated-code'
import {DownloadClient} from './downloadClient'
import {deleteFilesRequestMapper} from './mappers/request/deleteFilesRequestMapper'
import {listFilesRequestMapper} from './mappers/request/listFilesRequestMapper'
import {deleteFilesResponseMapper} from './mappers/response/deleteFilesResponseMapper'
import {listFilesResponseMapper} from './mappers/response/listFilesResponseMapper'

const logger = getLogger('DefaultFilesManagementService')

export interface FilesManagementService {
   listFiles(request: ListFilesRequest): Observable<ResponseWrapper<ListFilesRequest, ListFilesResponse>>

   deleteFiles(request: DeleteFilesRequest): Observable<ResponseWrapper<DeleteFilesRequest, DeleteFilesResponse>>

   downloadFile(request: DownloadFileRequest): Observable<ResponseWrapper<DownloadFileRequest, void>>
}

export class DefaultFilesManagementService implements FilesManagementService {
   constructor(
      private readonly _client: FilesManagementReactiveClient,
      private readonly _downloadClient: DownloadClient,
      private readonly _browserApi: BrowserApi,
   ) {}

   listFiles(request: ListFilesRequest): Observable<ResponseWrapper<ListFilesRequest, ListFilesResponse>> {
      logger.debug('About to send listFiles request', request)

      return this._client.listFiles(listFilesRequestMapper(request)).pipe(
         map(listFilesResponseMapper),
         requestResponseWrapper(request),
         authenticationCheck(this._browserApi),
         tap(({response, error}) => {
            if (error != null) {
               logger.warn('ListFiles request failed', error)
            } else if (response != null) {
               logger.debug(`ListFiles request was successful. Found [${response.entries.length}] files`, response)
            }
         }),
      )
   }

   deleteFiles(request: DeleteFilesRequest): Observable<ResponseWrapper<DeleteFilesRequest, DeleteFilesResponse>> {
      logger.debug('About to send deleteFiles request', request)

      return this._client.deleteFiles(deleteFilesRequestMapper(request)).pipe(
         map(deleteFilesResponseMapper),
         requestResponseWrapper(request),
         authenticationCheck(this._browserApi),
         tap(({response, error}) => {
            if (error != null) {
               logger.warn('DeleteFile request failed', error)
            } else if (response != null) {
               logger.debug(`DeleteFile request was successful.`, response)
            }
         }),
      )
   }

   downloadFile(request: DownloadFileRequest): Observable<ResponseWrapper<DownloadFileRequest, void>> {
      logger.debug('About to send downloadFile request', request)

      return this._downloadClient.download(request).pipe(requestResponseWrapper(request))
   }
}
