import {BrowserApi} from '@uieng/common'
import {ModuleApi, PolimerEpic} from '@uieng/polimer-js'
import {AuthActions, AuthenticationState} from '../authentication-api'
import {SimpleBrowserApi} from '../services'
import {WorkspaceRoutes} from '../shell-api'
import {getApplicationConfiguration} from '../system'
import {AllActions, ApplicaitonModuleKeys, ApplicationGlobalState, EpicDependencies} from '../system-api'
import {authenticationEpic} from './epics'
import {authenticationReducer} from './reducers'
import {AuthenticationService, DefaultAuthenticationService} from './services/authenticationService'
import {LoginContainer} from './view/loginContainer'

export interface AuthenticationDependencies {
   authService: AuthenticationService
   browserApi: BrowserApi
}
const config = getApplicationConfiguration()
export type AuthenticationEpic = PolimerEpic<
   AllActions,
   AllActions | AllActions[],
   ApplicationGlobalState,
   AuthenticationDependencies & EpicDependencies
>

export const authenticationModuleApi: ModuleApi<
   ApplicationGlobalState,
   AuthenticationState,
   void,
   AuthenticationDependencies
> = {
   moduleId: '',
   moduleKey: ApplicaitonModuleKeys.Authentication,
   reducer: authenticationReducer,
   epic: authenticationEpic,
   defaultViewContainer: LoginContainer,
   dependencies: (container: EpicDependencies) => ({
      authService: new DefaultAuthenticationService(container.authenticationClient),
      browserApi: new SimpleBrowserApi(config.authentication.redirectUrl),
   }),
   bootstrap: {
      bootstrapDependencies: [ApplicaitonModuleKeys.Routing, ApplicaitonModuleKeys.Shell],
      bootstrapStartAction: AuthActions.bootstrapStarted,
      bootstrapFinishedAction: AuthActions.bootstrapFinished,
   },
   routingConfig: {
      routeName: WorkspaceRoutes.Login,
      route: '/login/',
      loadOnStartup: true,
   },
}
