import {OperationDefinition} from '@uieng/messaging-api'
import {Observable} from 'rxjs'
import {DownloadFileRequest} from '../../directory-list-api'
import {downloadFilesDefinition} from '../../messaging-generated-code'

export interface DownloadClient {
   download(request: DownloadFileRequest): Observable<void>
}

export class DefaultDownloadClient implements DownloadClient {
   constructor(private readonly _uriPrefix: string) {}

   download(request: DownloadFileRequest): Observable<void> {
      return new Observable((observer) => {
         const url = this._getOperationUrl(downloadFilesDefinition, request.file)

         window.open(url, '_blank')

         observer.complete()
      })
   }

   private _getOperationUrl(operation: OperationDefinition, absolutePath: string): string {
      return `${this._uriPrefix}/${operation.requestName}?filelocation=${encodeURIComponent(absolutePath)}`
   }
}
