import {ofType} from '@martin_hotell/rex-tils'
import {combineEpics} from 'redux-observable'
import {map, switchMap} from 'rxjs/operators'
import {AuthenticationEpic} from '..'
import {getRoutingConfiguration} from '../../../routingConfiguration'
import {AuthActions, AuthActionTypes} from '../../authentication-api'
import {WorkspaceRoutes} from '../../shell-api'

/**
 * If the validation is successful, finish the bootstrap process and forward the user to the main app.
 * Otherwise, finish the bootstrap process and forward the user to the login screen.
 */
const validateTokenEpic: AuthenticationEpic = (action$, state$, deps) =>
   action$.pipe(
      ofType(AuthActionTypes.BOOTSTRAP_STARTED),
      switchMap(() =>
         deps.authService.validateToken({}).pipe(
            map((result) => {
               if (result.error != null) {
                  //stop login redirection if user is heading to reset-password page
                  const rc = getRoutingConfiguration().mapping[WorkspaceRoutes.ResetPassword].moduleApi.routingConfig
                  if (rc && deps.browserApi.getCurrentUrl().endsWith(rc.route)) {
                     return [AuthActions.bootstrapFinished()]
                  }

                  return [AuthActions.unauthenticated(), AuthActions.bootstrapFinished()]
               }

               return [AuthActions.authenticated(), AuthActions.bootstrapFinished()]
            }),
         ),
      ),
   )

export const bootstrapAuthModuleEpic = combineEpics(validateTokenEpic)
