import {UpdateProfileDetailsRequestDto} from '../../../messaging-generated-code'
import {UpdateProfileDetailsRequest} from '../../userProfileService'

export const updateProfileDetailsRequestMapper = (
   request: UpdateProfileDetailsRequest,
): UpdateProfileDetailsRequestDto => {
   return {
      accountName: request.accountName,
      externalContactName: request.contactName,
      externalContactEmail: request.contactEmail,
   }
}
