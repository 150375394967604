import {ofType} from '@martin_hotell/rex-tils'
import {mapToNonNullable} from '@uieng/common'
import {combineEpics} from 'redux-observable'
import {flatMap, map} from 'rxjs/operators'
import {AlertLevel, NotificationActions} from '../../shell-api'
import {ApplicationEpic} from '../../system-api'
import {UserProfileActions, UserProfiledActionTypes} from '../../user-profile-api'
import {getUserProfileFailedMessage} from './notificationMessages'

const doGetUserProfileEpic: ApplicationEpic = (action$, _, deps) =>
   action$.pipe(
      ofType(UserProfiledActionTypes.GET_PROFILE_DETAILS),
      flatMap(() => deps.userProfileService.getProfileDetails()),
      map(UserProfileActions.getProfileDetailsResult),
   )

const getUserProfileFailedEpic: ApplicationEpic = (action$) =>
   action$.pipe(
      ofType(UserProfiledActionTypes.GET_PROFILE_DETAILS_RESULT),
      mapToNonNullable((action) => action.payload.error),
      map((error) =>
         NotificationActions.showAlert({
            level: AlertLevel.Error,
            message: getUserProfileFailedMessage(error),
         }),
      ),
   )

export const getUserProfileEpic = combineEpics(doGetUserProfileEpic, getUserProfileFailedEpic)
