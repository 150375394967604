import {Button, Label, Popover, PopoverBody, ProgressBar} from '@gs-ux-uitoolkit-react/core'
import cn from 'classnames'
import React, {useCallback, useRef, useState} from 'react'
import {UploadState} from '../../../../file-upload-api'
import styles from './styles.module.scss'

interface Props {
   id: string
   progress: number
   fileName: string
   state: UploadState

   onCancelClick: (id: string) => void
}

const RenderRowItem: React.FC<Props> = (props) => {
   const {id, onCancelClick} = props
   const cancelButtonRef = useRef<HTMLButtonElement>(null)
   const [isPopoverOpen, setPopoverOpen] = useState(false)

   const handlePopoverToggle = useCallback(() => setPopoverOpen(!isPopoverOpen), [isPopoverOpen, setPopoverOpen])
   const handleCancelClick = useCallback(() => {
      onCancelClick(id)
      setPopoverOpen(false)
   }, [id, onCancelClick, setPopoverOpen])

   let cancelButton = (
      <React.Fragment>
         <Popover
            placement="top"
            isOpen={isPopoverOpen}
            target={cancelButtonRef}
            trigger="legacy"
            toggle={handlePopoverToggle}
            hideArrow={true}
         >
            <PopoverBody>
               <span>Are you sure you want to delete this file?</span>

               <div className={styles.confirmPopupActionsContainer}>
                  <Button color="tertiary" size="sm" onClick={handlePopoverToggle}>
                     No
                  </Button>
                  <Button color="danger" size="sm" onClick={handleCancelClick}>
                     Yes
                  </Button>
               </div>
            </PopoverBody>
         </Popover>
         <Button innerRef={cancelButtonRef} outline={true} color="secondary" size="sm" onClick={handlePopoverToggle}>
            <i className="gsi gsi-close" />
         </Button>
      </React.Fragment>
   )

   if (props.state !== UploadState.InProgress) {
      let icon,
         includeCancelButton = false

      if (props.state === UploadState.Completed) {
         icon = <i className={cn(styles.icon, styles.checkIcon, 'gsi gsi-checkbox-marked-circle')} title="Completed" />
      } else if (props.state === UploadState.Completing) {
         icon = <i className={cn(styles.icon, styles.loadingIcon, 'gsi gsi-loading')} title="Processing" />
         includeCancelButton = true
      } else if (props.state === UploadState.Aborted) {
         icon = <i className={cn(styles.icon, styles.closeIcon, 'gsi gsi-close-circle')} title="Aborted" />
      } else if (props.state === UploadState.Idle) {
         icon = <i className={cn(styles.icon, 'gsi gsi-clock-outline')} title="Waiting" />
         includeCancelButton = true
      }

      return (
         <div className={styles.rowContainer}>
            <span>{props.fileName}</span>

            <div className={styles.rowActionsContainer}>
               {icon}
               {includeCancelButton ? cancelButton : null}
            </div>
         </div>
      )
   }

   return (
      <div className={styles.rowContainer}>
         <div className={styles.progressContainer}>
            <Label>{props.fileName}</Label>
            <Label style={{float: 'right'}}>{props.progress}%</Label>
            <ProgressBar value={props.progress} />
         </div>

         {cancelButton}
      </div>
   )
}

export const RowItem = React.memo(RenderRowItem)
