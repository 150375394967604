import {getLogger} from '@uieng/logger'
import {MessagingClient, observableXhr, OperationDefinition} from '@uieng/messaging-api'
import {merge} from 'lodash'
import queryString from 'querystring'
import {defer, Observable, throwError} from 'rxjs'
import {filter, map, take, timeout} from 'rxjs/operators'

const logger = getLogger('RestMessagingClient')

export class RestMessagingClient implements MessagingClient<RequestInit> {
   private _uriPrefix: string = ''
   private _initialised: boolean = false

   constructor(private readonly _defaultRequestTimeousMs: number) {}

   initialise(uriPrefix: string = ''): void {
      this._uriPrefix = uriPrefix
      this._initialised = true
   }

   requestResponse<TRequest, TResponse>(
      operation: OperationDefinition<RequestInit>,
      request: TRequest,
      options?: RequestInit,
      requestTimeoutMs: number = this._defaultRequestTimeousMs,
   ): Observable<TResponse> {
      return defer(() => {
         if (!this._initialised) {
            return throwError(new Error('RestMessagingClient is not yet initialised!'))
         }

         const requestOptions: RequestInit = merge({}, operation.requestOptions, options)
         let url = this._getOperationUrl(operation)

         if (request != null) {
            if (requestOptions.method === 'POST') {
               logger.debug('Attaching stringified body to the request.')

               requestOptions.body = JSON.stringify(request)
            } else {
               const queryStr = queryString.stringify(request as any)

               if (queryStr.length > 0) {
                  logger.debug(
                     `Received [${requestOptions.method}] request. Attaching query parameters [${queryStr}] to the request.`,
                  )

                  url = `${url}?${queryStr}`
               }
            }
         }

         logger.debug(`About to call [${url}]`)

         return observableXhr<TResponse>(url, requestOptions)
      }).pipe(
         timeout(requestTimeoutMs),
         filter((event) => event.data != null && event.progress === 1),
         map((event) => event.data!),
         take(1),
      )
   }

   requestStream<TRequest, TResponse>(
      operation: OperationDefinition<RequestInit>,
      request: TRequest,
      options?: RequestInit,
      requestTimeoutMs?: number | undefined,
   ): Observable<TResponse> {
      return throwError(new Error('requestStream is not supported!'))
   }

   private _getOperationUrl(operation: OperationDefinition): string {
      return `${this._uriPrefix}/${operation.requestName}`
   }
}
