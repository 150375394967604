import {ResponseDefinition} from '@uieng/messaging-api'
import {useActionCallback} from '@uieng/polimer-js'
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {GetProfileDetailsResponse} from '../../services'
import {ApplicationGlobalState} from '../../system-api'
import {UpdateDetailsResult, UserProfileActions} from '../../user-profile-api'
import {UserProfileView} from './userProfileView'

interface StoreProps {
   profileDetailsResponse: ResponseDefinition<GetProfileDetailsResponse>
   updateDetailsResponse: ResponseDefinition<UpdateDetailsResult>
}

const RenderUserProfileContainer: React.FC = () => {
   const storeProps = useSelector<ApplicationGlobalState, StoreProps>((store) => ({
      profileDetailsResponse: store.userProfile.profileDetailsResponse,
      updateDetailsResponse: store.userProfile.updateDetailsResponse,
   }))

   const dispatch = useDispatch()

   const onSave = useActionCallback(UserProfileActions.updateProfileDetails, dispatch)
   const onGoBack = useActionCallback(UserProfileActions.goBack, dispatch)
   const onRetryFetch = useActionCallback(UserProfileActions.getProfileDetails, dispatch)

   return (
      <UserProfileView
         profileDetailsResponse={storeProps.profileDetailsResponse}
         updateDetailsResponse={storeProps.updateDetailsResponse}
         onSave={onSave}
         onGoBack={onGoBack}
         onRetryFetch={onRetryFetch}
      />
   )
}

export const UserProfileContainer = React.memo(RenderUserProfileContainer)
