import {createMutableReducer} from '@uieng/common'
import {ResponseState} from '@uieng/messaging-api'
import {DirectoryListActionTypes, DirectoryListState} from '../../directory-list-api'
import {AllActions, AllActionTypes} from '../../system-api'
import {getCurrentAbsolutePath} from '../selectors'

const initialState: DirectoryListState = {
   pathSeparator: '',
   currentPath: [],
   preRequestPath: [],
   absolutePathToResponseCache: {},

   listFilesResponse: {
      state: ResponseState.Idle,
   },
   deleteFilesResponse: {
      state: ResponseState.Idle,
   },
}

export const directoryListReducer = createMutableReducer<AllActionTypes, AllActions, DirectoryListState>(
   {
      [DirectoryListActionTypes.BOOTSTRAP_STARTED]: (draft, action) => {
         draft.pathSeparator = action.payload.directoryList.pathSeparator
      },
      [DirectoryListActionTypes.LIST_FILES]: (draft) => {
         draft.listFilesResponse.state = ResponseState.Loading
      },
      [DirectoryListActionTypes.LIST_FILES_RESULT]: (draft, action) => {
         const result = action.payload

         if (result.error != null) {
            draft.listFilesResponse.state = ResponseState.Error
         } else if (result.response != null) {
            draft.listFilesResponse.state = ResponseState.Success
            draft.listFilesResponse.data = result.response

            const absolutePath = getCurrentAbsolutePath(draft)
            draft.absolutePathToResponseCache[absolutePath] = result.response
         }
      },
      [DirectoryListActionTypes.ABORT_LIST_FILES]: (draft) => {
         draft.listFilesResponse.state = ResponseState.Success
      },
      [DirectoryListActionTypes.DELETE_FILE]: (draft) => {
         draft.deleteFilesResponse.state = ResponseState.Loading
      },
      [DirectoryListActionTypes.DELETE_FILE_RESULT]: (draft, action) => {
         const result = action.payload

         if (result.error != null) {
            draft.deleteFilesResponse.state = ResponseState.Error
         } else if (result.response != null) {
            draft.deleteFilesResponse.state = ResponseState.Success
            draft.deleteFilesResponse.data = result.response
         }
      },
      [DirectoryListActionTypes.UPDATE_CURRENT_PATH]: (draft, action) => {
         const path = action.payload.path
         const isPathEmpty = draft.currentPath.length === 0

         // In case currentPath is empty (during bootstrap start) use the payload value.
         draft.preRequestPath = isPathEmpty ? path.concat() : draft.currentPath.concat()
         draft.currentPath = path.concat()
      },
      [DirectoryListActionTypes.EXPIRE_PATH_FROM_CACHE]: (draft, action) => {
         const absolutePath = action.payload

         delete draft.absolutePathToResponseCache[absolutePath]
      },
   },
   initialState,
)
