export enum DeploymentType {
   /**
    * Use inconsistent value for internal so the users can't just go and replace the value 'external' with 'internal'
    * and gain unsupervised accesss.
    */
   Internal = 'sfxInternalDeployment',
   InternalChina = 'sfxInternalChinaDeployment',
   External = 'external',
   ExternalChina = 'sfxExternalChinaDeployment',
}
