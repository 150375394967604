import {ofType} from '@martin_hotell/rex-tils'
import {map} from 'rxjs/operators'
import {ShellEpic} from '..'
import {ShellActions, ShellActionTypes} from '../../shell-api'

export const bootstrapShellModuleEpic: ShellEpic = (action$) =>
   action$.pipe(
      ofType(ShellActionTypes.GET_USER_IP_ADDRESS_RESULT),
      map(() => ShellActions.bootstrapFinished()),
   )
