import {createMutableReducer} from '@uieng/common'
import {ResponseState} from '@uieng/messaging-api'
import {AllActions, AllActionTypes} from '../../system-api'
import {UpdateDetailsResult, UserProfiledActionTypes, UserProfileState} from '../../user-profile-api'

const initialState: UserProfileState = {
   profileDetailsResponse: {
      state: ResponseState.Idle,
   },
   updateDetailsResponse: {
      state: ResponseState.Idle,
   },
}

export const userProfileReducer = createMutableReducer<AllActionTypes, AllActions, UserProfileState>(
   {
      [UserProfiledActionTypes.GET_PROFILE_DETAILS]: (draft) => {
         draft.profileDetailsResponse.state = ResponseState.Loading
      },
      [UserProfiledActionTypes.GET_PROFILE_DETAILS_RESULT]: (draft, action) => {
         const result = action.payload

         delete draft.profileDetailsResponse.data

         if (result.error != null) {
            draft.profileDetailsResponse.state = ResponseState.Error
         } else if (result.response != null) {
            draft.profileDetailsResponse.state = ResponseState.Success
            draft.profileDetailsResponse.data = result.response
         }
      },
      [UserProfiledActionTypes.UPDATE_PROFILE_DETAILS]: (draft) => {
         draft.updateDetailsResponse.state = ResponseState.Loading
      },
      [UserProfiledActionTypes.UPDATE_PROFILE_DETAILS_RESULT]: (draft, action) => {
         const result = action.payload

         delete draft.updateDetailsResponse.data

         if (result.error != null) {
            draft.updateDetailsResponse.state = ResponseState.Error

            if (result.error.errorCode === 403 || result.error.errorCode === 400) {
               draft.updateDetailsResponse.data = UpdateDetailsResult.ForbiddenAction
            } else {
               draft.updateDetailsResponse.data = UpdateDetailsResult.ServerError
            }
         } else if (result.response != null) {
            draft.updateDetailsResponse.state = ResponseState.Success
            draft.updateDetailsResponse.data = UpdateDetailsResult.Success
         }
      },
   },
   initialState,
)
