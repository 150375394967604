import {AuthActionTypes, AuthActions} from '../../authentication-api'
import {flatMap, map, tap} from 'rxjs/operators'
import {RoutingActions, UrlUpdateStrategy} from '@uieng/polimer-js-routing'
import {AuthenticationEpic} from '..'
import {combineEpics} from 'redux-observable'
import {mapToNonNullable} from '@uieng/common'
import {ofType} from '@martin_hotell/rex-tils'
import {WorkspaceQueryParams} from '../../shell-api'

const doLogoutEpic: AuthenticationEpic = (action$, _, deps) =>
   action$.pipe(
      ofType(AuthActionTypes.LOGOUT),
      flatMap((action) => {
         return deps.authService.logout()
      }),
      map(AuthActions.logoutResult),
   )

const doStateResetEpic: AuthenticationEpic = (action$, _, deps) =>
   action$.pipe(
      ofType(AuthActionTypes.STATE_RESET),
      tap(() => deps.browserApi.reload()),
      map(AuthActions.unauthenticated),
   )

const redirectToLoginEpic: AuthenticationEpic = (action$, state$, deps) =>
   action$.pipe(
      ofType(AuthActionTypes.LOGOUT_RESULT),
      mapToNonNullable((action) => action.payload.response),
      map(() => [
         RoutingActions.unregisterQueryParams({
            updateStrategy: UrlUpdateStrategy.Replace,
            options: [
               {
                  global: true,
                  param: {name: WorkspaceQueryParams.Path, value: '', ignoreIfEmpty: false},
               },
               {
                  global: true,
                  param: {name: WorkspaceQueryParams.PasswordChanged, value: '', ignoreIfEmpty: false},
               },
               {
                  global: true,
                  param: {name: WorkspaceQueryParams.SessionTimeout, value: '', ignoreIfEmpty: false},
               },
            ],
         }),
         AuthActions.stateReset(),
      ]),
   )

export const logoutEpic = combineEpics(doLogoutEpic, redirectToLoginEpic, doStateResetEpic)

// export const redirectToLoginEpic: AuthenticationEpic = (action$, state$) =>
//    action$.pipe(
//       ofType(AuthActionTypes.UNAUTHENTICATED),
//       filter(() => isRouteVisible(state$.value.routing, WorkspaceRoutes.ResetPassword) === false),
//       map(() => RoutingActions.navigateToRoute(WorkspaceRoutes.Login, null, true, UrlUpdateStrategy.Replace)),
//    )
