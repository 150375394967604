import {Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Radio} from '@gs-ux-uitoolkit-react/core'

import {Attestation} from '../../file-upload-api'
import React from 'react'

interface Props {
   show: boolean
   onSubmit: (value: Attestation) => void
   onCancel: () => void
}

const RenderAttestationView: React.FC<Props> = ({show, onSubmit, onCancel}) => {
   const [value, setValue] = React.useState<Attestation | undefined>()

   return (
      <Modal isOpen={show} centered>
         <ModalHeader>Attestation</ModalHeader>
         <ModalBody>
            <p>
               I understand that based on the firm's Cross-Border Data Transfer framework, personal data, important
               data, data related to securities business activities and anti-money laundering data generated or
               collected as part of our business operations in China must be kept onshore, unless the Cross-Border Data
               transfer Working Group has approved an exemption to transfer such data to an offshore destination. Data
               transfer to onshore destinations is not subject to the Cross-Border Data Transfer rules.
            </p>

            <FormGroup>
               <Label>
                  Here I attest the data I am going to transfer thru SFX, including both the file name and file
                  contents, belong to the selected situation below, and I am responsible for the transfer activity and
                  consequences:
               </Label>
               <Radio name="radio1" onChange={() => setValue(Attestation.DATA_TRANSFER_OUT_OF_SCOPE)}>
                  The data transfer is out of scope of the CBDT rules
               </Radio>
               <Radio name="radio1" onChange={() => setValue(Attestation.DATA_TRANSFER_IN_SCOPE_AND_APPROVED)}>
                  The data transfer is in scope of the CBDT rules and the transfer has been approved
               </Radio>
               <Radio name="radio1" onChange={() => setValue(Attestation.DATA_TRANSFER_NOT_ALLOWED)}>
                  The data transfer use case does not match neither of the two cases above
               </Radio>
            </FormGroup>
         </ModalBody>
         <ModalFooter>
            <Button
               disabled={value === Attestation.DATA_TRANSFER_NOT_ALLOWED || !value}
               color="primary"
               onClick={() => {
                  if (!!value) onSubmit(value)
               }}
            >
               Proceed
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
         </ModalFooter>
      </Modal>
   )
}

export const AttestationView = React.memo(RenderAttestationView)
