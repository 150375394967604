import {ModuleApi} from '@uieng/polimer-js'
import {isUserAuthenticated} from '../authentication-api'
import {isInternalMode, isInternalChinaMode, WorkspaceRoutes} from '../shell-api'
import {ApplicaitonModuleKeys, ApplicationGlobalState} from '../system-api'
import {UserProfileState} from '../user-profile-api'
import {userProfileEpic} from './epics'
import {userProfileReducer} from './reducers'
import {UserProfileContainer} from './view/userProfileContainer'

export const userProfileModuleApi: ModuleApi<ApplicationGlobalState, UserProfileState> = {
   moduleId: '',
   moduleKey: ApplicaitonModuleKeys.UserProfile,
   epic: userProfileEpic,
   reducer: userProfileReducer,
   defaultViewContainer: UserProfileContainer,
   routingConfig: {
      routeName: WorkspaceRoutes.UserProfile,
      route: '/user-profile/',
      accessConfig: {
         isAccessRestricted: (state) => isInternalMode() === true || isInternalChinaMode() === true || isUserAuthenticated(state.auth) === false,
      },
   },
}
