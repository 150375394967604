import {ofType} from '@martin_hotell/rex-tils'
import {RoutingActions} from '@uieng/polimer-js-routing'
import {map} from 'rxjs/operators'
import {isUserAuthenticated} from '../../authentication-api'
import {ResetPasswordActionTypes} from '../../reset-password-api'
import {WorkspaceRoutes} from '../../shell-api'
import {ApplicationEpic} from '../../system-api'

export const navigateBackEpic: ApplicationEpic = (action$, state$) =>
   action$.pipe(
      ofType(ResetPasswordActionTypes.GO_BACK),
      map(() => {
         if (isUserAuthenticated(state$.value.auth) === true) {
            return RoutingActions.navigateToRoute(WorkspaceRoutes.DirectoryList)
         }

         return RoutingActions.navigateToRoute(WorkspaceRoutes.Login)
      }),
   )
