import {
   Button,
   Col,
   Container,
   Form,
   FormFeedback,
   FormGroup,
   Input,
   InputGroup,
   InputGroupAddon,
   Label,
   LoadingIcon,
   LoadingOverlay,
   Row,
} from '@gs-ux-uitoolkit-react/core'
import {Key, useFormState} from '@uieng/common'
import React, {useCallback, useState} from 'react'

import {ResponseState} from '@uieng/messaging-api'
import cn from 'classnames'
import styles from './styles.module.scss'

interface Props {
   loginResponseState: ResponseState
   isSessionTimedOut: boolean
   error?: string

   onSubmit: (username: string, password: string) => void
}

type ValidationState = 'success' | 'danger'

const RenderLoginView: React.FC<Props> = ({loginResponseState, isSessionTimedOut, error, onSubmit}) => {
   const [username, setUsername] = useFormState<string>('')
   const [password, setPassword] = useFormState<string>('')
   const [usernameState, setUsernameState] = useState<ValidationState>()
   const [usernameFeedback, setUsernameFeedback] = useState<string>()
   const [passwordState, setPasswordState] = useState<ValidationState>()
   const [passwordFeedback, setPasswordFeedback] = useState<string>()

   const handleSubmitClick = useCallback(() => {
      const isUsernameValid = username != null && username.length >= 3
      const isPasswordValid = password != null && password.length >= 3

      setUsernameState(isUsernameValid ? 'success' : 'danger')
      setPasswordState(isPasswordValid ? 'success' : 'danger')

      setUsernameFeedback(isUsernameValid ? undefined : 'The username is too short')
      setPasswordFeedback(isPasswordValid ? undefined : 'The password is too short')

      if (isUsernameValid === true && isPasswordValid === true) {
         onSubmit(username!, password!)
      }
   }, [setUsernameState, setUsernameFeedback, username, setPasswordState, setPasswordFeedback, password, onSubmit])

   const handleInputEnter = useCallback(
      (event: React.KeyboardEvent) => {
         if (event.charCode === Key.Enter) {
            handleSubmitClick()
         }
      },
      [handleSubmitClick],
   )

   let loginFeedbackMessage

   if (loginResponseState === ResponseState.Error) {
      loginFeedbackMessage = (
         <span className={cn('gs-uitk-text-body-03', styles.errorFeedbackMessage)}>
            {error || 'Login request failed. Please try again.'}
         </span>
      )
   } else if (isSessionTimedOut === true) {
      loginFeedbackMessage = (
         <span className={cn('gs-uitk-text-body-03', styles.errorFeedbackMessage)}>
            Your session timed out. Please login again.
         </span>
      )
   }

   return (
      <Container className={styles.loginRootContainer}>
         <Row className="justify-content-center">
            <Col sm="6" className={cn('gs-uitk-shadow-100', styles.contentPanel)}>
               <h4>Enter your login details</h4>

               <Form>
                  <FormGroup color={usernameState}>
                     <Label for="username">Username</Label>

                     <InputGroup>
                        <InputGroupAddon addonType="prepend">
                           <div className="input-group-text">
                              <i className="gsi gsi-account" />
                           </div>
                        </InputGroupAddon>

                        <Input
                           placeholder="Enter your username"
                           type="text"
                           name="username"
                           id="username"
                           state={usernameState}
                           valid={usernameState === 'success'}
                           invalid={usernameState === 'danger'}
                           value={username}
                           onKeyPress={handleInputEnter}
                           onChange={setUsername}
                        />

                        <FormFeedback valid={usernameState === 'success'}>{usernameFeedback}</FormFeedback>
                     </InputGroup>
                  </FormGroup>

                  <FormGroup color={passwordState}>
                     <Label for="password">Password</Label>

                     <InputGroup>
                        <InputGroupAddon addonType="prepend">
                           <div className="input-group-text">
                              <i className="gsi gsi-lock" />
                           </div>
                        </InputGroupAddon>

                        <Input
                           placeholder="Enter your password"
                           type="password"
                           name="password"
                           id="password"
                           state={passwordState}
                           valid={passwordState === 'success'}
                           invalid={passwordState === 'danger'}
                           value={password}
                           onKeyPress={handleInputEnter}
                           onChange={setPassword}
                        />

                        <FormFeedback valid={passwordState === 'success'}>{passwordFeedback}</FormFeedback>
                     </InputGroup>
                  </FormGroup>

                  <div>
                     <Button color="primary" onClick={handleSubmitClick}>
                        Submit
                     </Button>

                     {loginFeedbackMessage}
                  </div>

                  <LoadingOverlay show={loginResponseState === ResponseState.Loading}>
                     <LoadingIcon />
                  </LoadingOverlay>
               </Form>
            </Col>
         </Row>
      </Container>
   )
}

export const LoginView = React.memo(RenderLoginView)
