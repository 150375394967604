import {toastService} from '@gs-ux-uitoolkit-react/toast'
import {ModuleApi, PolimerEpic} from '@uieng/polimer-js'
import {ShellActions, ShellState} from '../shell-api'
import {
   AllActions,
   ApplicaitonModuleKeys,
   ApplicationConfiguration,
   ApplicationGlobalState,
   EpicDependencies,
} from '../system-api'
import {shellEpic} from './epics'
import {shellReducer} from './reducer'
import {DefaultNotificationService, NotificationService} from './services/notificationService'

export {LayoutContainer} from './view/layoutContainer'

export interface ShellDependencies {
   notificationService: NotificationService
}

export type ShellEpic = PolimerEpic<
   AllActions,
   AllActions | AllActions[],
   ApplicationGlobalState,
   ShellDependencies & EpicDependencies
>

export const shellModuleApi: ModuleApi<ApplicationGlobalState, ShellState, void, ShellDependencies> = {
   moduleId: '',
   moduleKey: ApplicaitonModuleKeys.Shell,
   reducer: shellReducer,
   epic: shellEpic,
   dependencies: () => ({
      notificationService: new DefaultNotificationService(toastService),
   }),
   bootstrap: {
      bootstrapDependencies: [ApplicaitonModuleKeys.Routing],
      bootstrapStartAction: (_, moduleApi, config: ApplicationConfiguration) => ShellActions.bootstrapStarted(config),
      bootstrapFinishedAction: ShellActions.bootstrapFinished,
   },
   dispose: {
      disposeStartAction: ShellActions.disposeStarted,
      disposeFinishedAction: ShellActions.disposeFinished,
   },
}
