import {ofType} from '@martin_hotell/rex-tils'
import {combineEpics} from 'redux-observable'
import {filter, flatMap, ignoreElements, map, tap} from 'rxjs/operators'
import {ResetPasswordActions, ResetPasswordActionTypes} from '../../reset-password-api'
import {ApplicationEpic} from '../../system-api'

const doChangePasswordEpic: ApplicationEpic = (action$, _, deps) =>
   action$.pipe(
      ofType(ResetPasswordActionTypes.CHANGE_PASSWORD),
      flatMap((action) => {
         const request = action.payload

         return deps.userProfileService.changePassword({
            username: request.username,
            currentPassword: request.currentPassword,
            newPassword: request.newPassword,
         })
      }),
      map(ResetPasswordActions.changePasswordResult),
   )

const redirectToLoginOnSuccessEpic: ApplicationEpic = (action$, state$, deps) =>
   action$.pipe(
      ofType(ResetPasswordActionTypes.CHANGE_PASSWORD_RESULT),
      filter((action) => action.payload.response != null),
      tap(() => {
         deps.browserApi.redirectTo(state$.value.shell.config?.authentication.passwordChangeSuccessRedirectUrl ?? '')
      }),
      ignoreElements(),
   )

export const changePasswordEpic = combineEpics(doChangePasswordEpic, redirectToLoginOnSuccessEpic)
