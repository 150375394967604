import {Observable, defer, throwError} from 'rxjs'
import {OperationDefinition, ResponseWrapper, requestResponseWrapper} from '@uieng/messaging-api'
import {UploadFileRequest, UploadFileResponse, UploadState} from '../../file-upload-api'
import {endWith, map, tap} from 'rxjs/operators'

import {BrowserApi} from '@uieng/common'
import {UploadClient} from './uploadClient'
import {authenticationCheck} from '../../authentication-api'
import {getLogger} from '@uieng/logger'
import {merge} from 'lodash'
import {uploadFileDefinition} from '../../messaging-generated-code'
import {uploadFileResponseMapper} from './mappers/response/uploadFileResponseMapper'

const logger = getLogger('FileUploadService')

export interface FileUploadService {
   upload(request: UploadFileRequest): Observable<ResponseWrapper<UploadFileRequest, UploadFileResponse>>
}

export class DefaultFileUploadService implements FileUploadService {
   private _uriPrefix: string = ''
   private _initialised: boolean = false

   constructor(private readonly _browserApi: BrowserApi, private readonly _client: UploadClient) {}

   initialise(uriPrefix: string = '') {
      this._uriPrefix = uriPrefix
      this._initialised = true
   }

   upload(request: UploadFileRequest): Observable<ResponseWrapper<UploadFileRequest, UploadFileResponse>> {
      return defer(() => {
         if (!this._initialised) {
            return throwError(new Error('DefaultFileUploadService is not yet initialised!'))
         }

         logger.debug('About to send fileUpload request', request)

         const operation = uploadFileDefinition
         const url = this._getOperationUrl(
            operation,
            request.descriptor.targetDirectory,
            request.descriptor.file.name,
            request.descriptor.attestation,
         )
         const requestOptions: RequestInit = merge({}, operation.requestOptions)

         const formData = new FormData()
         formData.append('file', request.descriptor.file)

         requestOptions.body = formData
         return this._client.upload(url, requestOptions)
      }).pipe(
         map(uploadFileResponseMapper),
         endWith({progress: 1, state: UploadState.Completed}),
         requestResponseWrapper(request),
         tap((result) => {
            if (result.error != null) {
               logger.warn('fileUpload request failed.', result.error)
            }
         }),
         authenticationCheck(this._browserApi),
      )
   }

   private _getOperationUrl(
      operation: OperationDefinition,
      location: string,
      fileName: string,
      attestation?: string,
   ): string {
      let fileLocation = `${location}/${fileName}`
      let url = `${this._uriPrefix}/${operation.requestName}?filelocation=${encodeURIComponent(fileLocation)}`

      if (attestation) {
         url += `&attestation=${attestation}`
      }

      return url
   }
}
