import {v4} from 'uuid'
import {FileEntry, ListFilesResponse} from '../../../../directory-list-api'
import {ListFilesResponseDto} from '../../../../messaging-generated-code'

export const listFilesResponseMapper = (result: ListFilesResponseDto[]): ListFilesResponse => {
   return {
      entries: result.map(mapFileResponseEntry),
   }
}

const mapFileResponseEntry = (value: ListFilesResponseDto): FileEntry => {
   return {
      id: v4(),
      absolutePath: value.absolutePath,
      fileName: value.fileName,
      hidden: value.hidden,
      directory: value.directory,
      file: value.file,
      link: value.link,
      doesExist: value.doesExist,
      readable: value.readable,
      writeable: value.writeable,
      removable: value.removable,
      ownerName: value.ownerName,
      groupName: value.groupName,
      linkCount: value.linkCount,
      lastModified: value.lastModified,
      size: value.size,
      permissions: value.permissions,
   }
}
