import {MessagingClient, OperationDefinition} from '@uieng/messaging-api'
import {Observable} from 'rxjs'

export interface ChangePasswordRequestDto {
   accountName: string
   oldPassword: string
   newPassword: string
}

export interface ChangePasswordResponseDto {}

export interface GetProfileDetailsResponseDto {
   accountName: string
   externalContactEmail: string
   externalContactName: string
}

export interface UpdateProfileDetailsRequestDto {
   accountName: string
   externalContactName: string
   externalContactEmail: string
}

export interface UpdateProfileDetailsResponseDto {}

const getIPAddressDefinition: OperationDefinition<RequestInit> = {
   requestName: 'get-ip-address',
   serviceName: '',
   requestOptions: {
      method: 'GET',
      headers: {
         'Content-Type': 'application/json',
      },
   },
}

const changePasswordDefinition: OperationDefinition<RequestInit> = {
   requestName: 'password/reset',
   serviceName: '',
   requestOptions: {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
      },
   },
}

const getProfileDetailsDefinition: OperationDefinition<RequestInit> = {
   requestName: 'accounts/external-attributes',
   serviceName: '',
   requestOptions: {
      method: 'GET',
      headers: {
         'Content-Type': 'application/json',
      },
   },
}

const updateProfileDetailsDefinition: OperationDefinition<RequestInit> = {
   requestName: 'accounts/external-attributes',
   serviceName: '',
   requestOptions: {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
      },
   },
}

export interface UserProfileReactiveClient {
   getIPAddress(options?: RequestInit): Observable<string>

   changePassword(request: ChangePasswordRequestDto, options?: RequestInit): Observable<ChangePasswordResponseDto>

   getProfileDetails(options?: RequestInit): Observable<GetProfileDetailsResponseDto>

   updateProfileDetails(
      request: UpdateProfileDetailsRequestDto,
      options?: RequestInit,
   ): Observable<UpdateProfileDetailsResponseDto>
}

export class DefaultUserProfileReactiveClient implements UserProfileReactiveClient {
   constructor(private readonly _messagingClient: MessagingClient<RequestInit>) {}

   getIPAddress(options?: RequestInit): Observable<string> {
      return this._messagingClient.requestResponse(getIPAddressDefinition, void 0, options)
   }

   changePassword(request: ChangePasswordRequestDto, options?: RequestInit): Observable<ChangePasswordResponseDto> {
      return this._messagingClient.requestResponse(changePasswordDefinition, request, options)
   }

   getProfileDetails(options?: RequestInit): Observable<GetProfileDetailsResponseDto> {
      return this._messagingClient.requestResponse(getProfileDetailsDefinition, void 0, options)
   }

   updateProfileDetails(
      request: UpdateProfileDetailsRequestDto,
      options?: RequestInit,
   ): Observable<UpdateProfileDetailsResponseDto> {
      return this._messagingClient.requestResponse(updateProfileDetailsDefinition, request, options)
   }
}
