import {ModuleApi, PolimerEpic} from '@uieng/polimer-js'
import {isUserAuthenticated} from '../authentication-api'
import {DirectoryListActions, DirectoryListState} from '../directory-list-api'
import {WorkspaceRoutes} from '../shell-api'
import {
   AllActions,
   ApplicaitonModuleKeys,
   ApplicationConfiguration,
   ApplicationGlobalState,
   EpicDependencies,
} from '../system-api'
import {directoryListEpic} from './epics'
import {directoryListReducer} from './reducer'
import {DefaultDownloadClient} from './services/downloadClient'
import {DefaultFilesManagementService, FilesManagementService} from './services/filesManagementService'
import {DirectoryListContainer} from './view/directoryListContainer'

export interface DirectoryListDependencies {
   filesService: FilesManagementService
}

export type DirectoryListEpic = PolimerEpic<
   AllActions,
   AllActions | AllActions[],
   ApplicationGlobalState,
   DirectoryListDependencies & EpicDependencies
>

export const directoryListModuleApi: ModuleApi<
   ApplicationGlobalState,
   DirectoryListState,
   void,
   DirectoryListDependencies
> = {
   moduleId: '',
   moduleKey: ApplicaitonModuleKeys.DirectoryList,
   reducer: directoryListReducer,
   epic: directoryListEpic,
   defaultViewContainer: DirectoryListContainer,
   dependencies: (container: EpicDependencies, config: ApplicationConfiguration) => ({
      filesService: new DefaultFilesManagementService(
         container.filesManagementClient,
         new DefaultDownloadClient(config.apiUriPrefix),
         container.browserApi,
      ),
   }),
   bootstrap: {
      bootstrapDependencies: [
         ApplicaitonModuleKeys.Routing,
         ApplicaitonModuleKeys.Shell,
         ApplicaitonModuleKeys.Authentication,
      ],
      bootstrapStartAction: (_, moduleApi, config: ApplicationConfiguration) =>
         DirectoryListActions.bootstrapStarted(config),
      bootstrapFinishedAction: DirectoryListActions.bootstrapFinished,
   },
   routingConfig: {
      route: '',
      exact: true,
      routeName: WorkspaceRoutes.DirectoryList,
      accessConfig: {
         isAccessRestricted: (state) => isUserAuthenticated(state.auth) === false,
      },
   },
}
