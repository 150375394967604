import {ofType} from '@martin_hotell/rex-tils'
import {RoutingActions, UrlUpdateStrategy} from '@uieng/polimer-js-routing'
import {map} from 'rxjs/operators'
import {AuthenticationEpic} from '..'
import {AuthActionTypes} from '../../authentication-api'
import {WorkspaceQueryParams, WorkspaceRoutes} from '../../shell-api'
import {isResetPasswordRequiredErr} from '../reducers'

export const handleUnauthenticated: AuthenticationEpic = (action$, state$) =>
   action$.pipe(
      ofType(AuthActionTypes.UNAUTHENTICATED),
      map(() => {
         const err = state$.value.auth.error
         if (!!err && isResetPasswordRequiredErr(err)) {
            return [
               RoutingActions.registerQueryParams({
                  updateStrategy: UrlUpdateStrategy.Replace,
                  options: [
                     {
                        global: true,
                        param: {name: WorkspaceQueryParams.ResetPasswordRequired, value: 'true', ignoreIfEmpty: false},
                     },
                  ],
               }),
               RoutingActions.navigateToRoute(WorkspaceRoutes.ResetPassword, null, true, UrlUpdateStrategy.Replace),
            ]
         }

         return RoutingActions.navigateToRoute(WorkspaceRoutes.Login, null, true, UrlUpdateStrategy.Replace)
      }),
   )
