import {LoadingIcon, LoadingOverlay} from '@gs-ux-uitoolkit-react/core'
import {ResponseState} from '@uieng/messaging-api'
import {ModuleApi, ModuleViewProvider} from '@uieng/polimer-js'
import React from 'react'

interface Props {
   className?: string
   activeModules: ModuleApi[]
   bootstrapState: ResponseState
}

const RenderWorkspaceView: React.FC<Props> = (props) => {
   if (props.bootstrapState === ResponseState.Loading) {
      return (
         <div className={props.className}>
            <LoadingOverlay show={true} fullScreen={true}>
               <LoadingIcon loadingLabel="Loading..." />
            </LoadingOverlay>
         </div>
      )
   }

   if (props.activeModules.length > 0) {
      return (
         <div className={props.className}>
            {props.activeModules.map((module) => {
               return <ModuleViewProvider key={module.moduleId} data-automation-id="moduleView" module={module} />
            })}
         </div>
      )
   }

   return <div className={props.className}>No active module detected!</div>
}

export const WorkspaceView = React.memo(RenderWorkspaceView)
