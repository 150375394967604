import {ActionsUnion, createAction} from '@martin_hotell/rex-tils'
import {ResponseWrapper} from '@uieng/messaging-api'
import {GetProfileDetailsResponse, UpdateProfileDetailsRequest, UpdateProfileDetailsResponse} from '../services'

export enum UserProfiledActionTypes {
   GO_BACK = '[user-profile] GO_BACK',

   UPDATE_PROFILE_DETAILS = '[user-profile] UPDATE_PROFILE_DETAILS',
   UPDATE_PROFILE_DETAILS_RESULT = '[user-profile] UPDATE_PROFILE_DETAILS_RESULT',

   GET_PROFILE_DETAILS = '[user-profile] GET_PROFILE_DETAILS',
   GET_PROFILE_DETAILS_RESULT = '[user-profile] GET_PROFILE_DETAILS_RESULT',
}

export const UserProfileActions = {
   goBack: () => createAction(UserProfiledActionTypes.GO_BACK),

   updateProfileDetails: (name: string, email: string) =>
      createAction(UserProfiledActionTypes.UPDATE_PROFILE_DETAILS, {name, email}),
   updateProfileDetailsResult: (result: ResponseWrapper<UpdateProfileDetailsRequest, UpdateProfileDetailsResponse>) =>
      createAction(UserProfiledActionTypes.UPDATE_PROFILE_DETAILS_RESULT, result),

   getProfileDetails: () => createAction(UserProfiledActionTypes.GET_PROFILE_DETAILS),
   getProfileDetailsResult: (result: ResponseWrapper<void, GetProfileDetailsResponse>) =>
      createAction(UserProfiledActionTypes.GET_PROFILE_DETAILS_RESULT, result),
}

export type UserProfileActions = ActionsUnion<typeof UserProfileActions>
