import {MessagingClient, OperationDefinition} from '@uieng/messaging-api'

import {Observable} from 'rxjs'
import {fromFetch} from 'rxjs/fetch'

export interface AuthenticateRequestDto {
   username: string
   password: string
}

export type AuthenticateResponseDto = string

const authenticateDefinition: OperationDefinition<RequestInit> = {
   requestName: 'authenticate',
   serviceName: '',
   requestOptions: {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
      },
   },
}
const validateTokenDefinition: OperationDefinition<RequestInit> = {
   requestName: 'validate-token',
   serviceName: '',
   requestOptions: {
      method: 'GET',
      headers: {
         'Content-Type': 'application/json',
      },
   },
}
const logoutDefinition: OperationDefinition<RequestInit> = {
   requestName: 'logout',
   serviceName: '',
   requestOptions: {
      method: 'GET',
      headers: {
         'Content-Type': 'application/json',
      },
   },
}

export interface AuthenticationReactiveClient {
   validateToken(options?: RequestInit): Observable<void>

   authenticate(request: AuthenticateRequestDto, options?: RequestInit): Observable<AuthenticateResponseDto>

   gsssoAuthenticate(options?: RequestInit): Observable<AuthenticateResponseDto>

   logout(options?: RequestInit): Observable<AuthenticateResponseDto>
}

export class DefaultAuthenticationReactiveClient implements AuthenticationReactiveClient {
   constructor(private readonly _messagingClient: MessagingClient<RequestInit>) {}

   validateToken(options?: RequestInit): Observable<void> {
      return this._messagingClient.requestResponse(validateTokenDefinition, void 0, options)
   }

   authenticate(request: AuthenticateRequestDto): Observable<AuthenticateResponseDto> {
      return fromFetch<string>(`/api/authenticate`, {
         method: 'POST',
         headers: {'Content-Type': 'application/json'},
         body: JSON.stringify(request),
         selector: (response) => {
            return response.text().then((data) => {
               if (response.status !== 200) {
                  // eslint-disable-next-line
                  throw {
                     errorCode: response.status,
                     errorType: response.statusText,
                     message: data,
                  }
               }

               return data
            })
         },
      })

      // return this._messagingClient.requestResponse(authenticateDefinition, request, options)
   }

   gsssoAuthenticate(options?: RequestInit): Observable<AuthenticateResponseDto> {
      return this._messagingClient.requestResponse(authenticateDefinition, undefined, options)
   }

   logout(options?: RequestInit): Observable<AuthenticateResponseDto> {
      return this._messagingClient.requestResponse(logoutDefinition, undefined, options)
   }
}
