import {ActionsUnion, createAction} from '@martin_hotell/rex-tils'
import {ResponseWrapper} from '@uieng/messaging-api'
import {ChangePasswordRequest, ChangePasswordResponse} from '../services'

export enum ResetPasswordActionTypes {
   GO_BACK = '[reset-password] GO_BACK',

   CHANGE_PASSWORD = '[reset-password] CHANGE_PASSWORD',
   CHANGE_PASSWORD_RESULT = '[reset-password] CHANGE_PASSWORD_RESULT',
}

export const ResetPasswordActions = {
   goBack: () => createAction(ResetPasswordActionTypes.GO_BACK),

   changePassword: (username: string, currentPassword: string, newPassword: string) =>
      createAction(ResetPasswordActionTypes.CHANGE_PASSWORD, {username, currentPassword, newPassword}),
   changePasswordResult: (result: ResponseWrapper<ChangePasswordRequest, ChangePasswordResponse>) =>
      createAction(ResetPasswordActionTypes.CHANGE_PASSWORD_RESULT, result),
}

export type ResetPasswordActions = ActionsUnion<typeof ResetPasswordActions>
