import {BrowserApi} from '@uieng/common'
import {ErrorType, ResponseWrapper} from '@uieng/messaging-api'
import {MonoTypeOperatorFunction} from 'rxjs'
import {tap} from 'rxjs/operators'

export function authenticationCheck<T extends ResponseWrapper<TRequest, TResponse>, TRequest, TResponse>(
   browserApi: BrowserApi,
): MonoTypeOperatorFunction<T> {
   return tap({
      next: (response) => {
         if (response.error != null) {
            if (response.error.errorType === ErrorType.Authentication) {
               browserApi.redirectToAuthPage()
            }
         }
      },
   })
}
