import {ActionsUnion, createAction} from '@martin_hotell/rex-tils'
import {LoginResponse, LogoutResponse} from './responses'

import {LoginRequest} from './requests'
import {ResponseWrapper} from '@uieng/messaging-api'

export enum AuthActionTypes {
   BOOTSTRAP_STARTED = '[auth] BOOTSTRAP_STARTED',
   BOOTSTRAP_FINISHED = '[auth] BOOTSTRAP_FINISHED',

   LOGIN = '[auth] LOGIN',
   LOGIN_RESULT = '[auth] LOGIN_RESULT',

   GSSSO_LOGIN = '[auth] GSSSO_LOGIN',
   GSSSO_LOGIN_RESULT = '[auth] GSSSO_LOGIN_RESULT',

   LOGOUT = '[auth] LOGOUT',
   LOGOUT_RESULT = '[auth] LOGOUT_RESULT',

   AUTHENTICATED = '[auth] AUTHENTICATED',
   UNAUTHENTICATED = '[auth] UNAUTHENTICATED',
   STATE_RESET = '[auth] STATE_RESET',
   UNAUTHORIZED = '[auth] UNAUTHORIZED',
}

export const AuthActions = {
   bootstrapStarted: () => createAction(AuthActionTypes.BOOTSTRAP_STARTED),
   bootstrapFinished: () => createAction(AuthActionTypes.BOOTSTRAP_FINISHED),

   login: (username: string, password: string) => createAction(AuthActionTypes.LOGIN, {username, password}),
   gsssoLogin: () => createAction(AuthActionTypes.GSSSO_LOGIN),
   loginResult: (result: ResponseWrapper<LoginRequest, LoginResponse>) =>
      createAction(AuthActionTypes.LOGIN_RESULT, result),
   gsssoLoginResult: (result: ResponseWrapper<void, LoginResponse>) =>
      createAction(AuthActionTypes.GSSSO_LOGIN_RESULT, result),
   logout: () => createAction(AuthActionTypes.LOGOUT),
   logoutResult: (result: ResponseWrapper<void, LogoutResponse>) => createAction(AuthActionTypes.LOGOUT_RESULT, result),

   authenticated: () => createAction(AuthActionTypes.AUTHENTICATED),
   unauthenticated: () => createAction(AuthActionTypes.UNAUTHENTICATED),
   stateReset: () => createAction(AuthActionTypes.STATE_RESET),
   unauthorized: () => createAction(AuthActionTypes.UNAUTHORIZED),
}

export type AuthActions = ActionsUnion<typeof AuthActions>
