import {ErrorDetails, ErrorType} from '@uieng/messaging-api'

export const getErrorReason = (details: ErrorDetails) => {
   let reason = ''

   if (details.errorType === ErrorType.Timeout) {
      reason = ' Reason: Timeout'
   } else if (details.errorType === ErrorType.Server && details.errorCode === 403) {
      reason = ' Reason: Operation not allowed'
   }

   return reason
}
