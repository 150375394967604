import { ResponseDefinition } from '@uieng/messaging-api'
import { useActionCallback } from '@uieng/polimer-js'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PasswordChangeResult, ResetPasswordActions } from '../../reset-password-api'
import { ApplicationGlobalState } from '../../system-api'
import { ResetPasswordView } from './resetPasswordView'
import { getQueryParam } from '@uieng/polimer-js-routing'
import { WorkspaceQueryParams } from '../../shell-api'

interface StoreProps {
   response: ResponseDefinition<PasswordChangeResult>
   isPasswordChangeRequired: boolean
}

const RenderResetPasswordContainer: React.FC = () => {
   const storeProps = useSelector<ApplicationGlobalState, StoreProps>((store) => ({
      response: store.resetPassword.changePasswordResponse,
      isPasswordChangeRequired:
         getQueryParam(store.routing, WorkspaceQueryParams.ResetPasswordRequired)?.value === 'true' ?? false,
   }))

   const dispatch = useDispatch()
   const onCancel = useActionCallback(ResetPasswordActions.goBack, dispatch)
   const onSubmit = useActionCallback(ResetPasswordActions.changePassword, dispatch)

   return (
      <ResetPasswordView
         isPasswordChangeRequired={storeProps.isPasswordChangeRequired}
         changePasswordResponse={storeProps.response}
         onCancel={onCancel}
         onSubmit={onSubmit}
      />
   )
}

export const ResetPasswordContainer = React.memo(RenderResetPasswordContainer)
