import './modules/system/extensions'

import {LoadModuleRequest, PolimerJS, PolimerProvider} from '@uieng/polimer-js'
import {routingModuleApi} from '@uieng/polimer-js-routing'
import React from 'react'
import ReactDOM from 'react-dom'
import {v4} from 'uuid'
import {LayoutContainer, shellModuleApi} from './modules/shell'
import {createContainer, getApplicationConfiguration} from './modules/system'
import {getRoutingConfiguration} from './routingConfiguration'

import './modules/styles/scss/styles.scss'

const applicationConfig = getApplicationConfiguration()
const polimerJs = PolimerJS.create({
   applicationConfig: applicationConfig,
   applicationDependencies: createContainer(applicationConfig),
})

const modules = [
   {
      moduleApi: routingModuleApi,
      bootstrapData: getRoutingConfiguration(),
   },
   {
      moduleApi: shellModuleApi,
   },
]

modules.forEach((module) => {
   const request: LoadModuleRequest<any> = {
      requestId: v4(),
      moduleApi: module.moduleApi,
      bootstrapData: module.bootstrapData,
   }

   polimerJs.loadModule(request)
})

ReactDOM.render(
   <PolimerProvider polimer={polimerJs}>
      <LayoutContainer />
   </PolimerProvider>,
   document.getElementById('root'),
)
