import {ErrorDetails} from '@uieng/messaging-api'
import {getErrorReason} from '../../services'

export const cannotDeleteEntryMessage = 'Delete operation not allowed.'

export const getListFilesFailedMessage = (details: ErrorDetails) =>
   `Request to list directories failed. Please try again.${getErrorReason(details)}`

export const getDeleteFilesFailedMessage = (details: ErrorDetails) =>
   `Request to delete file failed. Please try again.${getErrorReason(details)}`
