import {Modal, ModalBody, ModalFooter, ModalHeader} from '@gs-ux-uitoolkit-react/core'

import React from 'react'

interface Props {
   show: boolean
}

const RenderUnauthorizedView: React.FC<Props> = ({show}) => {
   return (
      <Modal isOpen={show} centered>
         <ModalHeader>Unauthorized</ModalHeader>
         <ModalBody>You do not have permission to access this service.</ModalBody>
         <ModalFooter></ModalFooter>
      </Modal>
   )
}

export const UnauthorizedView = React.memo(RenderUnauthorizedView)
