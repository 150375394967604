import {createMutableReducer} from '@uieng/common'
import {ResponseState} from '@uieng/messaging-api'
import {PasswordChangeResult, ResetPasswordActionTypes, ResetPasswordState} from '../../reset-password-api'
import {AllActions, AllActionTypes} from '../../system-api'

const initialState: ResetPasswordState = {
   changePasswordResponse: {
      state: ResponseState.Idle,
   },
}

export const resetPasswordReducer = createMutableReducer<AllActionTypes, AllActions, ResetPasswordState>(
   {
      [ResetPasswordActionTypes.CHANGE_PASSWORD]: (draft) => {
         draft.changePasswordResponse.state = ResponseState.Loading

         delete draft.changePasswordResponse.data
      },
      [ResetPasswordActionTypes.CHANGE_PASSWORD_RESULT]: (draft, action) => {
         const result = action.payload

         delete draft.changePasswordResponse.data

         if (result.error != null) {
            draft.changePasswordResponse.state = ResponseState.Error

            let invalidCredErrCodes = [400, 401, 409]

            if (invalidCredErrCodes.includes(result.error.errorCode)) {
               draft.changePasswordResponse.data = PasswordChangeResult.InvalidCredentials
            } else {
               draft.changePasswordResponse.data = PasswordChangeResult.ServerError
            }
         } else if (result.response != null) {
            draft.changePasswordResponse.state = ResponseState.Success
            draft.changePasswordResponse.data = PasswordChangeResult.Success
         }
      },
   },
   initialState,
)
