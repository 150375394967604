import {combineEpics} from 'redux-observable'
import {getUserProfileEpic} from './getUserProfileEpic'
import {navigateBackEpic} from './navigateBackEpic'
import {updateDetailsEpic} from './updateDetailsEpic'
import {userProfileRouteMadeVisible} from './userProfileMadeVisible'

export const userProfileEpic = combineEpics(
   navigateBackEpic,
   updateDetailsEpic,
   userProfileRouteMadeVisible,
   getUserProfileEpic,
)
