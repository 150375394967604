import {ofType} from '@martin_hotell/rex-tils'
import {mapToNonNullable} from '@uieng/common'
import {getLogger} from '@uieng/logger'
import {combineEpics} from 'redux-observable'
import {flatMap, map} from 'rxjs/operators'
import {DirectoryListEpic} from '..'
import {DirectoryListActions, DirectoryListActionTypes} from '../../directory-list-api'
import {AlertLevel, NotificationActions} from '../../shell-api'
import {cannotDeleteEntryMessage, getDeleteFilesFailedMessage} from './notificationMessages'

const logger = getLogger('DirectoryListLogger')

const requestFileDeletionEpic: DirectoryListEpic = (action$) =>
   action$.pipe(
      ofType(DirectoryListActionTypes.DELETE_FILE_REQUESTED),
      map((action) => {
         const entry = action.payload

         if (entry.removable === false) {
            logger.warn(`Requested to delete file [${entry.absolutePath}] that is marked as non-removable. Ignoring.`)

            return NotificationActions.showAlert({
               level: AlertLevel.Error,
               message: cannotDeleteEntryMessage,
            })
         }

         return DirectoryListActions.deleteFile(entry)
      }),
   )

const doDeleteFilesEpic: DirectoryListEpic = (action$, state$, deps) =>
   action$.pipe(
      ofType(DirectoryListActionTypes.DELETE_FILE),
      flatMap((action) => {
         const entry = action.payload

         return deps.filesService
            .deleteFiles({
               files: [entry.absolutePath],
            })
            .pipe(map(DirectoryListActions.deleteFileResult))
      }),
   )

const deleteFilesFailedEpic: DirectoryListEpic = (action$) =>
   action$.pipe(
      ofType(DirectoryListActionTypes.DELETE_FILE_RESULT),
      mapToNonNullable((action) => action.payload.error),
      map((error) =>
         NotificationActions.showAlert({
            level: AlertLevel.Error,
            message: getDeleteFilesFailedMessage(error),
         }),
      ),
   )

const requestRefreshOnSuccessEpic: DirectoryListEpic = (action$) =>
   action$.pipe(
      ofType(DirectoryListActionTypes.DELETE_FILE_RESULT),
      mapToNonNullable((action) => action.payload.response),
      map(() => DirectoryListActions.listFilesRequested(true)),
   )

export const deleteFilesEpic = combineEpics(
   doDeleteFilesEpic,
   deleteFilesFailedEpic,
   requestRefreshOnSuccessEpic,
   requestFileDeletionEpic,
)
