import {ofType} from '@martin_hotell/rex-tils'
import {isRouteVisible, RoutingActions, UrlUpdateStrategy} from '@uieng/polimer-js-routing'
import {filter, map} from 'rxjs/operators'
import {AuthenticationEpic} from '..'
import {AuthActionTypes} from '../../authentication-api'
import {WorkspaceQueryParams, WorkspaceRoutes} from '../../shell-api'

export const redirectToDirectoryListEpic: AuthenticationEpic = (action$, state$) =>
   action$.pipe(
      ofType(AuthActionTypes.AUTHENTICATED),
      filter(() => isRouteVisible(state$.value.routing, WorkspaceRoutes.ResetPassword) === false),
      map(() => [
         RoutingActions.unregisterQueryParams({
            ignoreUpdateUrl: false,
            updateStrategy: UrlUpdateStrategy.Replace,
            options: [
               {
                  param: {name: WorkspaceQueryParams.PasswordChanged, value: '', ignoreIfEmpty: false},
               },
               {
                  param: {name: WorkspaceQueryParams.SessionTimeout, value: '', ignoreIfEmpty: false},
               },
            ],
         }),
         RoutingActions.navigateToRoute(WorkspaceRoutes.DirectoryList, null, true, UrlUpdateStrategy.Replace),
      ]),
   )
