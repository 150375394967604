import {
   Button,
   Col,
   Container,
   Form,
   FormFeedback,
   FormGroup,
   Input,
   InputGroup,
   InputGroupAddon,
   Label,
   LoadingIcon,
   LoadingOverlay,
   Row,
} from '@gs-ux-uitoolkit-react/core'
import {Key} from '@uieng/common'
import {ResponseDefinition, ResponseState} from '@uieng/messaging-api'
import cn from 'classnames'
import React, {useCallback, useEffect, useState} from 'react'
import {GetProfileDetailsResponse} from '../../services'
import {UpdateDetailsResult} from '../../user-profile-api'
import styles from './styles.module.scss'

interface Props {
   profileDetailsResponse: ResponseDefinition<GetProfileDetailsResponse>
   updateDetailsResponse: ResponseDefinition<UpdateDetailsResult>

   onRetryFetch: () => void
   onSave: (name: string, email: string) => void
   onGoBack: () => void
}

type ValidationState = 'success' | 'danger'

const RenderUserProfileView: React.FC<Props> = (props) => {
   const [name, setName] = useState<string>('')
   const [email, setEmail] = useState<string>('')
   const [nameState, setNameState] = useState<ValidationState>()
   const [nameFeedback, setNameFeedback] = useState<string>()
   const [emailState, setEmailState] = useState<ValidationState>()
   const [emailFeedback, setEmailFeedback] = useState<string>()

   const handleNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value), [
      setName,
   ])
   const handleEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value), [
      setEmail,
   ])

   const {profileDetailsResponse, onSave, onRetryFetch} = props

   useEffect(() => {
      // Once the profile data has been fetched, set the initial values into the inputs
      if (profileDetailsResponse.data != null) {
         setName(profileDetailsResponse.data.contactName)
         setEmail(profileDetailsResponse.data.contactEmail)
      }
   }, [profileDetailsResponse.data])

   const handleSubmitClick = useCallback(() => {
      const isNameValid = name != null && name.length >= 3
      const isEmailValid = email != null && email.length >= 3

      setNameState(isNameValid ? 'success' : 'danger')
      setEmailState(isEmailValid ? 'success' : 'danger')

      setNameFeedback(isNameValid ? undefined : 'Your name is too short')
      setEmailFeedback(isEmailValid ? undefined : 'Your email is too short')

      if (isNameValid === true && isEmailValid === true) {
         onSave(name!, email!)
      }
   }, [setNameState, setNameFeedback, name, setEmailState, setEmailFeedback, email, onSave])

   const handleInputEnter = useCallback(
      (event: React.KeyboardEvent) => {
         if (event.charCode === Key.Enter) {
            handleSubmitClick()
         }
      },
      [handleSubmitClick],
   )

   let feedbackMessage

   if (props.profileDetailsResponse.state === ResponseState.Error) {
      const message = `Couldn't fetch profile details. Please try again.`

      feedbackMessage = (
         <p className={cn('gs-uitk-text-body-03', styles.errorFeedbackMessage)}>
            {message}
            <Button color="primary" size="sm" onClick={onRetryFetch}>
               Retry
            </Button>
         </p>
      )
   } else if (props.updateDetailsResponse.state === ResponseState.Error) {
      const message =
         props.updateDetailsResponse.data === UpdateDetailsResult.ServerError
            ? 'Profile details update failed due to server error. Please try again.'
            : 'You do not have permissions to update profile details for this account.'

      feedbackMessage = <p className={cn('gs-uitk-text-body-03', styles.errorFeedbackMessage)}>{message}</p>
   } else if (props.updateDetailsResponse.state === ResponseState.Success) {
      const message = 'Your profile details have been updated successfully.'

      feedbackMessage = <p className={cn('gs-uitk-text-body-03', styles.successFeedbackMessage)}>{message}</p>
   }

   const isLoading =
      props.updateDetailsResponse.state === ResponseState.Loading ||
      props.profileDetailsResponse.state === ResponseState.Loading

   const isDisabled = props.profileDetailsResponse.state === ResponseState.Error

   return (
      <Container className={styles.rootContainer}>
         <Row className="justify-content-center">
            <Col sm="6" className={cn('gs-uitk-shadow-100', styles.contentPanel)}>
               <h4>Your profile details</h4>

               <Form>
                  <FormGroup color={nameState}>
                     <Label for="name">External contact name</Label>

                     <InputGroup>
                        <InputGroupAddon addonType="prepend">
                           <div className="input-group-text">
                              <i className="gsi gsi-account" />
                           </div>
                        </InputGroupAddon>

                        <Input
                           placeholder="Enter your name"
                           type="text"
                           name="name"
                           id="name"
                           disabled={isDisabled}
                           state={nameState}
                           valid={nameState === 'success'}
                           invalid={nameState === 'danger'}
                           value={name}
                           onKeyPress={handleInputEnter}
                           onChange={handleNameChange}
                        />

                        <FormFeedback valid={nameState === 'success'}>{nameFeedback}</FormFeedback>
                     </InputGroup>
                  </FormGroup>

                  <FormGroup color={emailState}>
                     <Label for="email">External contact email</Label>

                     <InputGroup>
                        <InputGroupAddon addonType="prepend">
                           <div className="input-group-text">
                              <i className="gsi gsi-email" />
                           </div>
                        </InputGroupAddon>

                        <Input
                           placeholder="Enter your email"
                           type="text"
                           name="email"
                           id="email"
                           disabled={isDisabled}
                           state={emailState}
                           valid={emailState === 'success'}
                           invalid={emailState === 'danger'}
                           value={email}
                           onKeyPress={handleInputEnter}
                           onChange={handleEmailChange}
                        />

                        <FormFeedback valid={emailState === 'success'}>{emailFeedback}</FormFeedback>
                     </InputGroup>
                  </FormGroup>

                  {feedbackMessage}

                  <div className={styles.actionsContainer}>
                     <Button onClick={props.onGoBack}>Go back</Button>

                     <Button color="primary" disabled={isDisabled} onClick={handleSubmitClick}>
                        Save
                     </Button>
                  </div>

                  <LoadingOverlay show={isLoading}>
                     <LoadingIcon />
                  </LoadingOverlay>
               </Form>
            </Col>
         </Row>
      </Container>
   )
}

export const UserProfileView = React.memo(RenderUserProfileView)
