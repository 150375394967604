import {Button} from '@gs-ux-uitoolkit-react/core'
import cn from 'classnames'
import React, {useCallback} from 'react'
import {FileUploadEntry, UploadState, ViewState} from '../../file-upload-api'
import {UploadList} from './components/uploadList'
import styles from './styles.module.scss'

interface Props {
   viewState: ViewState
   entries: FileUploadEntry[]

   onViewStateChanged: (state: ViewState) => void
   onClearClick: () => void
   onCancelClick: (id: string) => void
}

const RenderFileUploadView: React.FC<Props> = (props) => {
   const {viewState, onViewStateChanged} = props

   const handleMinimiseToggle = useCallback(() => {
      if (viewState === ViewState.Maximised) {
         onViewStateChanged(ViewState.Minimised)
      } else {
         onViewStateChanged(ViewState.Maximised)
      }
   }, [viewState, onViewStateChanged])
   const handleCloseClick = useCallback(() => onViewStateChanged(ViewState.Closed), [onViewStateChanged])

   const itemsUploading = props.entries.filter(
      (item) => item.state === UploadState.InProgress || item.state === UploadState.Completing,
   ).length
   const itemsCompleted = props.entries.filter((item) => item.state === UploadState.Completed).length

   let headerLabel = 'No files uploading'
   if (itemsUploading > 0) {
      headerLabel = itemsUploading + ' files uploading'
   } else if (itemsCompleted > 0) {
      headerLabel = itemsCompleted + ' files uploaded'
   }

   const containerClasses = cn(styles.rootContainer, 'gs-uitk-shadow-100', {
      [styles.rootContainerMinimised]: viewState === ViewState.Minimised,
      [styles.rootContainerMaximised]: viewState === ViewState.Maximised,
      [styles.rootContainerClosed]: viewState === ViewState.Closed,
   })
   const minimiseIconClasses = cn(styles.minimiseIcon, 'gsi gsi-chevron-up', {
      [styles.minimiseIconOpen]: viewState === ViewState.Maximised,
   })

   return (
      <div className={containerClasses}>
         <div className={styles.headerContainer}>
            <span>{headerLabel}</span>

            <div>
               <Button className={styles.headerButton} color="secondary" onClick={props.onClearClick}>
                  <i className="gsi gsi-cancel" title="Clear completed" />
               </Button>
               <Button className={styles.headerButton} color="secondary" onClick={handleMinimiseToggle}>
                  <i className={minimiseIconClasses} title="Minimise/Maximise" />
               </Button>
               <Button className={styles.headerButton} color="secondary" onClick={handleCloseClick}>
                  <i className="gsi gsi-close" title="Close" />
               </Button>
            </div>
         </div>

         <div className={styles.contentContainer}>
            <UploadList items={props.entries} onCancelClick={props.onCancelClick} />
         </div>
      </div>
   )
}

export const FileUploadView = React.memo(RenderFileUploadView)
