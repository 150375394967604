import {RoutingActions, UrlUpdateStrategy} from '@uieng/polimer-js-routing'

import {AuthActionTypes} from '../../authentication-api'
import {AuthenticationEpic} from '..'
import {WorkspaceRoutes} from '../../shell-api'
import {map} from 'rxjs/operators'
import {ofType} from '@martin_hotell/rex-tils'

export const redirectToUnauthorizedEpic: AuthenticationEpic = (action$, state$) =>
   action$.pipe(
      ofType(AuthActionTypes.UNAUTHORIZED),
      map(() => RoutingActions.navigateToRoute(WorkspaceRoutes.Unauthorized, null, true, UrlUpdateStrategy.Replace)),
   )
