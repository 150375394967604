import {ofType} from '@martin_hotell/rex-tils'
import {getLogger} from '@uieng/logger'
import {getQueryParam, RoutingActions, RoutingActionTypes, UrlUpdateStrategy} from '@uieng/polimer-js-routing'
import {combineEpics} from 'redux-observable'
import {filter, flatMap, map} from 'rxjs/operators'
import {DirectoryListEpic} from '..'
import {DirectoryListActions, DirectoryListActionTypes} from '../../directory-list-api'
import {WorkspaceQueryParams} from '../../shell-api'
import {getCurrentAbsolutePath} from '../selectors'

const logger = getLogger('DirectoryListLogger')

const fileSelectedEpic: DirectoryListEpic = (action$, state$) =>
   action$.pipe(
      ofType(DirectoryListActionTypes.FILE_SELECTED),
      filter((action) => action.payload.directory === true),
      flatMap((action) => {
         const updatedPathParts = state$.value.directoryList.currentPath.concat()
         updatedPathParts.push(action.payload.fileName)

         return [
            DirectoryListActions.updateCurrentPath(updatedPathParts, true),
            DirectoryListActions.listFilesRequested(),
         ]
      }),
   )

const breadcrumbClickedEpic: DirectoryListEpic = (action$, state$) =>
   action$.pipe(
      ofType(DirectoryListActionTypes.BREADCRUMB_CLICK),
      flatMap((action) => {
         const state = state$.value
         const index = action.payload
         let requestedPathParts

         // "Home" breadcrumb gets special treatment.
         if (index === 0) {
            requestedPathParts = state.shell.config?.directoryList.homeLocation.concat() ?? []
         } else {
            requestedPathParts = state.directoryList.currentPath.filter((_, itemIndex) => itemIndex <= index)
         }

         return [
            DirectoryListActions.updateCurrentPath(requestedPathParts, true),
            DirectoryListActions.listFilesRequested(),
         ]
      }),
   )

const pathInUrlChangedEpic: DirectoryListEpic = (action$, state$) =>
   action$.pipe(
      ofType(RoutingActionTypes.QUERY_PARAMS_UPDATED),
      flatMap(() => {
         const queryParam = getQueryParam(state$.value.routing, WorkspaceQueryParams.Path)
         const currentAbsolutePath = getCurrentAbsolutePath(state$.value.directoryList)

         if (queryParam == null || currentAbsolutePath === queryParam.value) {
            logger.debug('Detected query parameters change, but the path parameter did not change. Ignoring.')
            return []
         }

         logger.debug(
            `Detected query parameters change, path parameter changed from [${currentAbsolutePath}] to [${queryParam.value}].`,
         )

         const path = queryParam.value ?? currentAbsolutePath
         const pathParts = path.split(state$.value.shell.config?.directoryList.pathSeparator ?? '/')

         return [DirectoryListActions.updateCurrentPath(pathParts, false), DirectoryListActions.listFilesRequested()]
      }),
   )

const registerPathParamEpic: DirectoryListEpic = (action$, state$) =>
   action$.pipe(
      ofType(DirectoryListActionTypes.UPDATE_CURRENT_PATH),
      filter((action) => action.payload.updateQueryParams === true),
      map(() => {
         const currentPath = getCurrentAbsolutePath(state$.value.directoryList)

         logger.debug(`Current path updated to [${currentPath}]. Updating path query parameter.`)

         return RoutingActions.registerQueryParams({
            updateStrategy: UrlUpdateStrategy.Push,
            options: [
               {
                  global: true,
                  param: {
                     name: WorkspaceQueryParams.Path,
                     value: currentPath,
                     ignoreIfEmpty: false,
                  },
               },
            ],
         })
      }),
   )

export const navigationEpic = combineEpics(
   fileSelectedEpic,
   breadcrumbClickedEpic,
   pathInUrlChangedEpic,
   registerPathParamEpic,
)
