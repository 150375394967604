import {ofType} from '@martin_hotell/rex-tils'
import {ignoreElements, tap} from 'rxjs/operators'
import {ShellEpic} from '..'
import {NotificationActionTypes} from '../../shell-api'

export const notificationsEpic: ShellEpic = (action$, _, deps) =>
   action$.pipe(
      ofType(NotificationActionTypes.SHOW_ALERT),
      tap((action) => deps.notificationService.showAlert(action.payload)),
      ignoreElements(),
   )
