import {ofType} from '@martin_hotell/rex-tils'
import {mapToNonNullable} from '@uieng/common'
import {getLogger} from '@uieng/logger'
import {combineEpics} from 'redux-observable'
import {map, switchMap, takeUntil} from 'rxjs/operators'
import {DirectoryListEpic} from '..'
import {DirectoryListActions, DirectoryListActionTypes, ListFilesRequest} from '../../directory-list-api'
import {AlertLevel, NotificationActions} from '../../shell-api'
import {getCurrentAbsolutePath} from '../selectors'
import {getListFilesFailedMessage} from './notificationMessages'

const logger = getLogger('DirectoryListLogger')

const listFilesRequestedEpic: DirectoryListEpic = (action$, state$) =>
   action$.pipe(
      ofType(DirectoryListActionTypes.LIST_FILES_REQUESTED),
      map((action) => {
         const directoryState = state$.value.directoryList

         const force = action.payload.force === true
         const requestedAbsolutePath = action.payload.absolutePath
         const currentAbsolutePath = getCurrentAbsolutePath(directoryState)
         const path = requestedAbsolutePath ?? currentAbsolutePath

         const isCached = directoryState.absolutePathToResponseCache[path] != null
         const request: ListFilesRequest = {
            location: path,
         }

         if (force === false && isCached === true) {
            logger.debug(`Path [${path}] is already cached. Skipping request to list files.`)

            return [
               DirectoryListActions.listFilesResult({
                  request: request,
                  response: directoryState.absolutePathToResponseCache[path],
               }),

               // There might be a request that is in-flight, but because we effectively navigated away
               // that request needs to be aborted.
               DirectoryListActions.abortListFiles(),
            ]
         }

         if (requestedAbsolutePath != null && requestedAbsolutePath !== currentAbsolutePath) {
            logger.debug(
               `Requested to refresh path [${requestedAbsolutePath}] but the current path is [${currentAbsolutePath}]. This means the requested path will be removed from cache (if it is cached) and listed next time the user visits it.`,
            )

            // Expire the path from cache only in case of a forced refresh
            if (force === true) {
               return DirectoryListActions.expirePathFromCache(requestedAbsolutePath)
            }

            return []
         }

         return DirectoryListActions.listFiles(request)
      }),
   )

const doListFilesEpic: DirectoryListEpic = (action$, _, deps) =>
   action$.pipe(
      ofType(DirectoryListActionTypes.LIST_FILES),
      switchMap((action) => {
         const request = action.payload

         logger.debug(`Requesting to list directory [${request.location}].`)

         return deps.filesService
            .listFiles(request)
            .pipe(takeUntil(action$.pipe(ofType(DirectoryListActionTypes.ABORT_LIST_FILES))))
      }),
      map(DirectoryListActions.listFilesResult),
   )

const listFilesFailedEpic: DirectoryListEpic = (action$, state$) =>
   action$.pipe(
      ofType(DirectoryListActionTypes.LIST_FILES_RESULT),
      mapToNonNullable((action) => action.payload.error),
      map((error) => {
         logger.debug('Request to list directory failed. Showing alert and navigating back.')

         return [
            NotificationActions.showAlert({
               level: AlertLevel.Error,
               message: getListFilesFailedMessage(error),
            }),
            DirectoryListActions.updateCurrentPath(state$.value.directoryList.preRequestPath.concat(), true),
         ]
      }),
   )

export const listFilesEpic = combineEpics(listFilesRequestedEpic, doListFilesEpic, listFilesFailedEpic)
