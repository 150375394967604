export enum WorkspaceRoutes {
   Login = 'Login',

   DirectoryList = 'DirectoryList',

   FileUpload = 'FileUpload',

   ResetPassword = 'ResetPassword',

   UserProfile = 'UserProfile',

   Unauthorized = 'Unauthorized',
}
