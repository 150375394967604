import {FileUploadEpic} from '..'
import {ofType} from '@martin_hotell/rex-tils'
import {FileUploadActionTypes, ViewState, FileUploadActions} from '../../file-upload-api'
import {filter, map} from 'rxjs/operators'

export const showViewUponUploadEpic: FileUploadEpic = (action$, state$) =>
   action$.pipe(
      ofType(FileUploadActionTypes.REQUEST_FILES_UPLOAD),
      filter(() => state$.value.fileUpload.viewState === ViewState.Closed),
      map(() => FileUploadActions.setViewState(ViewState.Maximised)),
   )
