import {ofType} from '@martin_hotell/rex-tils'
import {flatMap, map} from 'rxjs/operators'
import {ShellEpic} from '..'
import {ShellActions, ShellActionTypes} from '../../shell-api'

export const getUserIpAddressEpic: ShellEpic = (action$, _, deps) =>
   action$.pipe(
      ofType(ShellActionTypes.BOOTSTRAP_STARTED),
      flatMap(() => deps.userProfileService.getIPAddress()),
      map(ShellActions.getUserIpAddressResult),
   )
