import {LoginRequest, LoginResponse, LogoutResponse, ValidateTokenRequest} from '../../authentication-api'
import {ResponseWrapper, requestResponseWrapper} from '@uieng/messaging-api'

import {AuthenticationReactiveClient} from '../../messaging-generated-code'
import {Observable} from 'rxjs'
import {authenticateRequestMapper} from './mappers/request/authenticateRequestMapper'
import {getLogger} from '@uieng/logger'
import {tap} from 'rxjs/operators'

const logger = getLogger('DefaultAuthenticationService')

export interface AuthenticationService {
   validateToken(request: ValidateTokenRequest): Observable<ResponseWrapper<ValidateTokenRequest, void>>

   login(request: LoginRequest): Observable<ResponseWrapper<LoginRequest, LoginResponse>>

   gsssoLogin(): Observable<ResponseWrapper<void, LoginResponse>>

   logout(): Observable<ResponseWrapper<void, LogoutResponse>>
}

export class DefaultAuthenticationService implements AuthenticationService {
   constructor(private readonly _authClient: AuthenticationReactiveClient) {}

   validateToken(request: ValidateTokenRequest): Observable<ResponseWrapper<ValidateTokenRequest, void>> {
      logger.debug('About to send validate token request', request)

      return this._authClient.validateToken().pipe(
         requestResponseWrapper(request),
         tap(({error}) => {
            if (error != null) {
               logger.warn('ValidateToken request failed', error)
            } else {
               logger.debug(`ValidateToken request was successful`)
            }
         }),
      )
   }

   login(request: LoginRequest): Observable<ResponseWrapper<LoginRequest, LoginResponse>> {
      logger.debug('About to send login request', {usernaem: request.username})

      return this._authClient.authenticate(authenticateRequestMapper(request)).pipe(
         requestResponseWrapper(request),
         tap(({error}) => {
            if (error != null) {
               logger.warn('Login request failed', error)
            }
         }),
      )
   }

   gsssoLogin(): Observable<ResponseWrapper<void, LoginResponse>> {
      logger.debug('About to send gssso login request')

      return this._authClient.gsssoAuthenticate().pipe(
         requestResponseWrapper(),
         tap(({error}) => {
            if (error != null) {
               logger.warn('Login request failed', error)
            }
         }),
      )
   }

   logout(): Observable<ResponseWrapper<void, LogoutResponse>> {
      logger.debug('About to send logout request')

      return this._authClient.logout().pipe(
         requestResponseWrapper(),
         tap(({error}) => {
            if (error != null) {
               logger.warn('Logout request failed', error)
            }
         }),
      )
   }
}
