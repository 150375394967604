import {ModuleApi} from '@uieng/polimer-js'
import {ResetPasswordState} from '../reset-password-api'
import {WorkspaceRoutes} from '../shell-api'
import {ApplicationGlobalState} from '../system-api'
import {resetPasswordEpic} from './epics'
import {resetPasswordReducer} from './reducers'
import {ResetPasswordContainer} from './view/resetPasswordContainer'

export const resetPasswordModuleApi: ModuleApi<ApplicationGlobalState, ResetPasswordState> = {
   moduleId: '',
   moduleKey: 'resetPassword',
   reducer: resetPasswordReducer,
   epic: resetPasswordEpic,
   defaultViewContainer: ResetPasswordContainer,
   routingConfig: {
      routeName: WorkspaceRoutes.ResetPassword,
      route: '/reset-password',
      loadOnStartup: true,
   },
}
