import {ApplicationConfiguration} from '../../system-api'

export function getApplicationConfiguration(): ApplicationConfiguration {
   const defaultLocationEnv = process.env.REACT_APP_DEFAULT_LOCATION
   const defaultLocation =
      defaultLocationEnv != null && defaultLocationEnv.length > 0 ? defaultLocationEnv.split('/') : ['.']

   return {
      authentication: {
         redirectUrl: '/login/?sessionTimeout=true',
         passwordChangeSuccessRedirectUrl: '/login/?passwordChanged=true',
      },
      directoryList: {
         pathSeparator: '/',
         defaultLocation: defaultLocation,
         homeLocation: ['.'],
      },
      fileUpload: {
         maxConcurrentUploads: 3,
      },

      version: process.env.REACT_APP_BUILD_VERSION ?? '',
      apiUriPrefix: '/api',
      requestTimeoutMs: 60_000,
   }
}
