import {ListGroup, ListGroupItem} from '@gs-ux-uitoolkit-react/core'
import React from 'react'
import {FileUploadEntry} from '../../../../file-upload-api'
import {RowItem} from './rowItem'

interface Props {
   items: FileUploadEntry[]

   onCancelClick: (id: string) => void
}

const RenderUploadList: React.FC<Props> = (props) => {
   return (
      <ListGroup>
         {props.items.map((item) => {
            const progress = Math.round(item.progress * 100)

            return (
               <ListGroupItem key={item.id}>
                  <RowItem
                     id={item.id}
                     fileName={item.label}
                     progress={progress}
                     state={item.state}
                     onCancelClick={props.onCancelClick}
                  />
               </ListGroupItem>
            )
         })}
      </ListGroup>
   )
}

export const UploadList = React.memo(RenderUploadList)
