import {ActionsUnion, createAction} from '@martin_hotell/rex-tils'
import {Attestation, UploadDescriptor} from './model/uploadDescriptor'

import {ResponseWrapper} from '@uieng/messaging-api'
import {UploadFileRequest} from './requests'
import {UploadFileResponse} from './responses'
import {ViewState} from './model/viewState'

export enum FileUploadActionTypes {
   SET_VIEW_STATE = '[upload] SET_VIEW_STATE',
   REQUEST_FILES_UPLOAD = '[upload] REQUEST_FILES_UPLOAD',
   CANCEL_FILE_UPLOAD = '[upload] CANCEL_FILE_UPLOAD',
   CLEAR_UPLOAD_LIST = '[upload] CLEAR_UPLOAD_LIST',

   ENQUEUE_FILES_UPLOAD = '[upload] ENQUEUE_FILES_UPLOAD',
   UPLOAD_FILES = '[upload] UPLOAD_FILES',
   UPLOAD_FILES_RESULT = '[upload] UPLOAD_FILES_RESULT',

   SHOW_FILE_UPLOAD_ATTESTATION = '[upload] SHOW_FILE_UPLOAD_ATTESTATION',
   HIDE_FILE_UPLOAD_ATTESTATION = '[upload] HIDE_FILE_UPLOAD_ATTESTATION',
   SET_FILE_UPLOAD_ATTESTATION = '[upload] SET_FILE_UPLOAD_ATTESTATION',
}

export const FileUploadActions = {
   setViewState: (state: ViewState) => createAction(FileUploadActionTypes.SET_VIEW_STATE, state),

   // Use this action to initiate upload for a file. This will go through internal queueing mechanism
   requestFilesUpload: (descriptors: UploadDescriptor[]) =>
      createAction(FileUploadActionTypes.REQUEST_FILES_UPLOAD, descriptors),
   cancelFileUpload: (id: string) => createAction(FileUploadActionTypes.CANCEL_FILE_UPLOAD, id),
   clearUploadList: () => createAction(FileUploadActionTypes.CLEAR_UPLOAD_LIST),

   uploadFiles: (descriptors: UploadDescriptor[]) => createAction(FileUploadActionTypes.UPLOAD_FILES, descriptors),
   uploadFilesResult: (result: ResponseWrapper<UploadFileRequest, UploadFileResponse>) =>
      createAction(FileUploadActionTypes.UPLOAD_FILES_RESULT, result),
   enqueueFilesUpload: (descriptors: UploadDescriptor[]) =>
      createAction(FileUploadActionTypes.ENQUEUE_FILES_UPLOAD, descriptors),

   showFileUploadAttestation: (descriptors: UploadDescriptor[]) =>
      createAction(FileUploadActionTypes.SHOW_FILE_UPLOAD_ATTESTATION, descriptors),
   hideFileUploadAttestation: () => createAction(FileUploadActionTypes.HIDE_FILE_UPLOAD_ATTESTATION),
   setFileUploadAttestation: (attestation: Attestation) =>
      createAction(FileUploadActionTypes.SET_FILE_UPLOAD_ATTESTATION, attestation),
}

export type FileUploadActions = ActionsUnion<typeof FileUploadActions>
