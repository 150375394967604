import {AuthActionTypes, AuthActions} from '../../authentication-api'
import {flatMap, map} from 'rxjs/operators'

import {AuthenticationEpic} from '..'
import {combineEpics} from 'redux-observable'
import {mapToNonNullable} from '@uieng/common'
import {ofType} from '@martin_hotell/rex-tils'
import {isResetPasswordRequiredErr} from '../reducers'
import {isNil} from 'lodash'

const dologinEpic: AuthenticationEpic = (action$, _, deps) =>
   action$.pipe(
      ofType(AuthActionTypes.LOGIN),
      flatMap((action) => {
         return deps.authService.login({
            username: action.payload.username,
            password: action.payload.password,
         })
      }),
      map(AuthActions.loginResult),
   )

const handleLoginResultEpic: AuthenticationEpic = (action$) =>
   action$.pipe(
      ofType(AuthActionTypes.LOGIN_RESULT),
      map((action) => {
         const err = action.payload.error
         if (!isNil(err) && err.errorCode === 401 && isResetPasswordRequiredErr(err.message)) {
            return AuthActions.unauthenticated()
         }
         if (!isNil(action.payload.response)) {
            return AuthActions.authenticated()
         }
         return []
      }),
   )

const gsssoLoginEpic: AuthenticationEpic = (action$, _, deps) =>
   action$.pipe(
      ofType(AuthActionTypes.GSSSO_LOGIN),
      flatMap(() => {
         return deps.authService.gsssoLogin()
      }),
      map((props) => {
         if (props.error?.errorCode === 401 || props.error?.errorCode === 403) {
            return [AuthActions.unauthorized()]
         }

         return [AuthActions.gsssoLoginResult(props)]
      }),
   )

const redirectToDirectoryListEpicGssso: AuthenticationEpic = (action$) =>
   action$.pipe(
      ofType(AuthActionTypes.GSSSO_LOGIN_RESULT),
      mapToNonNullable((action) => action.payload.response),
      map(() => AuthActions.authenticated()),
   )

export const loginEpic = combineEpics(
   dologinEpic,
   handleLoginResultEpic,
   gsssoLoginEpic,
   redirectToDirectoryListEpicGssso,
)
