export enum Attestation {
   DATA_TRANSFER_OUT_OF_SCOPE = "cbdt_excluded",
   DATA_TRANSFER_IN_SCOPE_AND_APPROVED = "cbdt_approved",
   DATA_TRANSFER_NOT_ALLOWED = "not_compliant",
}

export interface UploadDescriptor {
   id: string
   targetDirectory: string
   file: File
   attestation?: Attestation
}
